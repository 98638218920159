import React, { useState } from 'react'
import Styles from "./ProductReview.module.scss";
import { CardPrice, CardTitle } from '../../atoms/text/CardTitle';
import { Stack } from '@mui/material';
import MButton from '../../atoms/button/MButton';
import CDivider from '../../atoms/Divider/Divider';

export default function ProductReview(props) {
    const { item, cancel } = props

    const [rating, setRating] = useState(0)
    const [ratingHover, setRatingHover] = useState(0)
    const [review, setReview] = useState("")

    const cancelBtn = () => {
        cancel(false)
        setRating('')
        setRatingHover("")
        setReview("")
    }
    const submitReview = () => {
        const payload = {
            id: item.id,
            rating: rating,
            review: review
        }
        console.log("payload", payload);

        cancelBtn()
    }
    return (
        <div className={Styles.mainContainer}>
            <section className={Styles.pageTitle}>
                <div className={Styles.heading}>Leave a Ratings & Review </div>
                <CDivider />
            </section>



            <div className={Styles.viewBox}>
                <div className={Styles.productCard}>
                    <div className={Styles.imageContainer}>
                        <div
                            style={{ backgroundImage: `url(https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg)`, }}
                            className={Styles.image}
                        />
                    </div>
                    <Stack justifyContent={"space-between"} overflow={"hidden"}>
                        <div>
                            <CardTitle>{item?.item_name}</CardTitle>
                        </div>
                        <CardPrice>${item?.order_price}</CardPrice>
                    </Stack>
                </div>
                {/* <div className={Styles.longBar} /> */}
                <Stack gap={"inherit"} justifyContent={"space-between"} width={"100%"}>
                    <div className={Styles.rightSideBox} >
                        <Stack spacing={1.2}>
                            <div className={Styles.heading}>How is your order?</div>
                            <div className={Styles.desc}>Please give your rating & also your review...</div>
                        </Stack>
                        {/* <div className={Styles.ratingContainer}>
                            <StarOutlinedIcon sx={{ fontSize: "32px" }} />
                            <StarOutlinedIcon sx={{ fontSize: "32px" }} />
                            <StarOutlinedIcon sx={{ fontSize: "32px" }} />
                            <StarOutlinedIcon sx={{ fontSize: "32px" }} />
                            <StarBorderOutlinedIcon sx={{ fontSize: "32px" }} />
                        </div> */}
                        <div className={Styles.ratingContainer}>
                            {[...Array(5)].map((_, index) => {
                                return (
                                    <span
                                    key={index}
                                        className={`${Styles.starIcon} ${index <= ((rating && ratingHover) || ratingHover) ? Styles.starSelected : ""}`}
                                        onClick={() => setRating(index)}
                                        onMouseOver={() => setRatingHover(index)}
                                        onMouseLeave={() => setRatingHover(rating)}
                                    >
                                        &#9733;
                                    </span>
                                )
                            })}
                        </div>
                        <CDivider />
                    </div>
                    <div>
                        <CardTitle>Review this product</CardTitle>
                        <input
                            value={review}
                            className={Styles.input}
                            placeholder='Very good product & fast delivery!'
                            onChange={(e) => setReview(e.target.value)}
                        />
                    </div>
                </Stack>
            </div>

            <div className={Styles.longBar} />
            <div className={Styles.btnBox}>
                <MButton fullWidth variant="secondary" onClick={cancelBtn}>Cancel</MButton>
                <MButton fullWidth onClick={submitReview} >Submit</MButton>
            </div>
        </div>
    )
}
