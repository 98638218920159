import {
  Box,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import textStyles from "../TextStyles.module.scss";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { ArrowForwardRounded, } from "@mui/icons-material";
import Styles from "./page.module.scss";
import Text from "../../../atoms/text/Text";
import CartCard from "./cartCard";
import AddressCard from "../../../molecules/AddressCard/AddressCard";
import { CardTitle } from "../../../atoms/text/CardTitle";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";


import AddNewAddress1 from "../../../molecules/AddressCard/AddNewAddress1";
import MButton from "../../../atoms/button/MButton";
import CustomeModal from "../../../atoms/Modal/CustomeModal";
import { useDispatch } from "react-redux";
import { setupcomingPath } from "../../../../store/reducers/Index";


export default function DesktopCart(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const { cart, setDeleteItem, userData } = props

  //useState variable
  const [openAddressList, setOpenAddressList] = useState(false)

  const vals = cart.map((idx) => idx.item_price * idx.count);
  // const totalPrice = vals.reduce((prev, curr) => prev + curr, 0);
  let totalPrice = 0;
  for (let i = 0; i < vals.length; i++) {
    totalPrice = totalPrice + Number(vals[i])
  }

  const openAddressPopup = () => {
    setOpenAddressList(!openAddressList)
  }


  const checkOut1 = () => {
    if (location.pathname === "/cart") {
      if (userData?.id) {
        if (userData?.address?.trim() !== "" && userData?.address !== null && userData?.address !== undefined) {
          navigate("/checkoutProceesing")
        } else {
          alert("please Enter Delivery Address")
        }

      } else {
        navigate("/signin")
        dispatch(setupcomingPath("/checkoutProceesing"))
      }
    } else {
      navigate("/cart")
    }

  }

  return (
    <section className={Styles.container} >


      <Grid container spacing={8}>
        <Grid item xs={12} md={7}>
          <Stack spacing={3} mb={3}>
            <Stack direction="row" spacing={3} alignItems="center">
              <FmdGoodOutlinedIcon />
              <div href="" className={textStyles.bld}>
                Check delivery and collect options
              </div>
            </Stack>
          </Stack>
          <Stack spacing={4}>
            {
              cart.map((item, index) => {
                return (
                  <div key={index}>
                    <CartCard
                      item={item}
                      openDelete={() => {
                        setDeleteItem({ data: item, state: true });
                      }}
                    />
                    <hr />
                  </div>)
              })
            }

          </Stack>
        </Grid>

        <Grid item xs={12} md={5}>
          <div className={Styles.rightSideContainer}>
            <Text className={textStyles.bldLg}>Order summary</Text>
            <Stack
              mt={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text className={textStyles.txt}>Products price</Text>
              <span className={textStyles.bld}>Rs.{totalPrice}</span>
            </Stack>
            <Stack spacing={1} my={2}>
              <Text className={textStyles.txt}>Delivery charges</Text>
              <Text className={textStyles.txtSm}>calculated at the next step</Text>
            </Stack>
            <Divider sx={{ border: "1px black solid" }} />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              my={2}
            >
              <Text className={textStyles.bld}>Subtotal</Text>
              <Text className={textStyles.bldXl}>Rs.{totalPrice}</Text>
            </Stack>

            <hr />
            <div className={Styles.checkOutBox}>
              <Stack width="100%" my={2} justifyContent="space-between">
                <Stack my={1}>
                  <CardTitle>Shipping address</CardTitle>
                  <AddressCard
                    item={userData}
                    edit={openAddressPopup}
                  />
                </Stack>


                <Box width="100%">

                  <MButton onClick={checkOut1} sq={true} sx={{ p: "1.8rem 1.5rem", height: "auto" }}>
                    <div className={`${textStyles.bldLg} ${Styles.checkoutBtn}`}>
                      Continue to checkout
                      <ArrowForwardRounded
                        sx={{
                          color: "black",
                          width: "40px",
                          padding: "8px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#fff",
                          fontSize: "1.5rem",

                        }}
                      />
                    </div>
                  </MButton>
                </Box>
              </Stack>
              <a href="" className={textStyles.txt}>
                Return policy
              </a>
            </div>
          </div>
        </Grid>
      </Grid>


      {
        <CustomeModal title="Add Address" outSide={false} show={openAddressList} setShow={setOpenAddressList}>
          <AddNewAddress1 close={openAddressPopup} profileData={userData} />
        </CustomeModal>
      }
    </section>
  );
}

