import React from 'react'
import LoginScreen from '../../components/pages/login/loginComponent'

function LoginPage() {
  return (
    <div>
      <LoginScreen />
    </div>
  )
}

export default LoginPage
