import Styles from "./Styles.module.scss"

function LabelText(props) {
    const { children } = props
    return (
        <label className={Styles.labelText}>
            {children}
        </label>
    )
}

export default LabelText