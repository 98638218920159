import React,{useState,useEffect} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'; 
import FormLabel from '@mui/material/FormLabel';

export default function CRadio(props) { 
  const { item, name, selectedAddress, onAddressChange } = props;

  const handleChange = (event) => {
    // onAddressChange(event.target.value);
    onAddressChange(item);
  };

  // useEffect(() => {
  //   if (selectedAddress !== undefined) {
  //     onAddressChange(selectedAddress);
  //   }
  // }, [selectedAddress, onAddressChange]);

  return (
    <FormControl> 
      <RadioGroup
        row
        name={name}
        // value={selectedAddress}
        value={selectedAddress?.name === item.name ? item.name : ''}
        onChange={handleChange}
      >
        {/* <FormControlLabel value={props.option1} control={<Radio />} label={props.option1} /> */}
        <FormControlLabel 
        value={item.name} 
        control={<Radio />} 
        // label={props.option2} 
        /> 
      </RadioGroup>
    </FormControl>
  );
}





// export default function ControlledRadioButtonsGroup() {
//   const [value, setValue] = React.useState('female');

//   const handleChange = (event) => {
//     setValue(event.target.value);
//   };

//   return (
//     <FormControl>
//       <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
//       <RadioGroup
//         aria-labelledby="demo-controlled-radio-buttons-group"
//         name="controlled-radio-buttons-group"
//         value={value}
//         onChange={handleChange}
//       >
//         <FormControlLabel value="female" control={<Radio />} label="Female" />
//         <FormControlLabel value="male" control={<Radio />} label="Male" />
//       </RadioGroup>
//     </FormControl>
//   );
// }