"use client";

import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox, Stack } from "@mui/material";
import {
  CheckBox,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";

export default function MenuCheckbox({
  label,
  list,
  onFilterChange,
  selectedFilters,
  onToggleDrawer,
  matches,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (matches) {
      setAnchorEl(event.currentTarget);
    } else if (!matches) {
      onToggleDrawer();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        sx={{
          backgroundColor: "#eeeeee",
          color: "black",
          borderRadius: "18px",
          textTransform: "capitalize",
          padding: "0.5rem 1.5rem",
          fontWeight: "600",
          fontSize: "0.8rem",
        }}
      >
        {label}
      </Button>
      <Menu
        sx={{ marginTop: "20px" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {list.map((item) => (
          <MenuItem key={item.id}>
            <Stack
              width="200px"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <label htmlFor={item.id} style={{
                width: "100%", display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                 {item.name}

                <Checkbox
                  id={item.id}
                  name={item.name}
                  onChange={(e) => onFilterChange(e, item)}
                  checked={selectedFilters.find((f) => f.id === item.id)}
                />
              </label>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
