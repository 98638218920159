
import { Grid } from "@mui/material";
import styles from "./index.module.scss";

export const HeaderCompoment = ({ tab, setTab }) => {
  return (
    <>


      <Grid display="flex">
        <div
          onClick={() => setTab("Ongoing")}
          className={`${styles.Ongoing} ${tab === "Ongoing" ? styles.show : ""
            }`}
        >
          Ongoing
        </div>
        <div
          onClick={() => setTab("Completed")}
          className={`${styles.Ongoing} ${tab === "Completed" ? styles.show : ""
            }`}
        >
          Completed
        </div>
      </Grid>
    </>
  );
};




