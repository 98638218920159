import { Grid } from "@mui/material";
import Styles from "./Categories.module.css";
import CategoryMixture from "./CategoryMixture/CategoryMixture";
import grocery from "./assets/grocery.png";
import shirt from "./assets/shirt.png";
import hotel from "./assets/hotel.png";
import mobile from "./assets/mobile.png";
import vehicle from "./assets/vehicle.png";
import plane from "./assets/plane.png";
import Text from "../atoms/text/Text";



import accessorisIcon from "./assets/helmet.png";
import electronicsIcon from "./assets/electronics.png";
import food from "./assets/food-delivery.png";
import homeAppliance from "./assets/home-appliance.png";
import fashionIcon from "./assets/fashion-brand.png";
import { CardTitle } from "../atoms/text/CardTitle";

const categories = [
  {
    id: 1,
    category1: {
      name: "Daily",
      subcategories: [
        {
          id:1,
          name: "Fashion",
          image: shirt,
           url: "fashion"
        },
        {
          id:2,
          name: "Accessories",
          image: accessorisIcon,
          url: "accessories"
        },
      ],
    },
    category2: {
      name: "Electronics",
      image: electronicsIcon,
      url: "electronics"
    },
    category3: {
      name: "Fashion",
      image: fashionIcon,
      url: "fashion"
    },
  },
  {
    id: 2,
    category1: {
      name: "Home",
      subcategories: [
        {
          id: 1,
          name: "Appliances",
          image: homeAppliance,
          url: "appliances"
        },
        {
          id: 2,
          name: "Food",
          image: food,
          url: "food"
        },
      ],
    },
    category2: {
      name: "Appliances",
      image: homeAppliance,
      url: "appliances"
    },
    category3: {
      name: "Accessories",
      image: accessorisIcon,
      url: "accessories"
    },
  },
];

export default function HomePageCategories() {
  return (
    <div className={Styles.container}>
      <div className={Styles.heading}>
        {/* <Text className={Styles.subtitle}>Choose your category</Text> */}
        <CardTitle>Categories</CardTitle>
      </div>
      <Grid container spacing={4}>
        {categories.map((cat, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            key={cat.id}>
            <CategoryMixture
              flexDirection={(i + 1) % 2 === 0 ? "row-reverse" : "row"}
              data={cat}
            />
          </Grid>
        ))}

      </Grid>
    </div>
  );
}
