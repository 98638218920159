import { Link, useLocation, useNavigate } from "react-router-dom";
import { navs } from "../../pages/home/data"; 
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import WholphinLogo from "../../wholphinLogo/WholphinLogo";
import TopnavSearchComponent from "../../searchComponent/TopnavSearchComponent/TopnavSearchComponent";
import useAuthNavigate from "../../../hooks/useAuthNavigate";
import AddressShowSingleLine from "../../AddressShowSingleLine/AddressShowSingleLine";



export const TopNavComponent = () => {
  const location = useLocation();
  const cart = useSelector((state) => state.products.cart);
  const authNavigate = useAuthNavigate()
  const count = cart.map((idx) => idx.count);
  const totalCount = count.reduce((prev, curr) => prev + curr, 0);

  return (
    <div className={styles.TopNavCompoment}>

      <section className={styles.topNav}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1.8rem", width: "50%", }}>
          <div className={styles.logoImg} >
            <WholphinLogo />
          </div>
          <TopnavSearchComponent />
        </Box>

        <Box >
          <div style={{ maxWidth: "120px", margin: "0 auto" }}>
            <AddressShowSingleLine />
          </div>
        </Box>

        <div className={styles.iconsTopNav}>
          {navs.map(({ name, icon, url }, index) => {
            return (
              <div
                onClick={() => authNavigate(url)}
                key={url}
                style={{ position: "relative" }}
                className={`${styles.iconTopNav} ${location.pathname === url ? styles.activePage : ""} `}
              >
                {index === 1 && totalCount > 0 && (
                  <div className={`${styles.bagde} ${styles.annim}`}>{totalCount}</div>
                )}

                <div className={styles.icon}>{icon}</div>
                <div className={styles.name}>{name}</div>
              </div>
            );
          })}
        </div>
      </section>



    </div>
  );
};
