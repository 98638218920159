import React from 'react'
import Styles from "./Skeletonproduct.module.scss"
import { CircularProgress, Skeleton, Stack } from '@mui/material'
import Text from '../text/Text'
function SkeletonProduct() {
    return (
        <div className={Styles.container}>
            <Skeleton variant="rectangular" width={"100%"} height={200} />
            <Skeleton variant="text" width={"50%"} />
            <Skeleton variant="text" width={"40%"} />
        </div>
    )
}

export default SkeletonProduct

export const LoaderCircle = () => {
    return (
        <Stack
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <CircularProgress sx={{color:"black"}} />
            <Text>Loading...</Text>
        </Stack>
    )
}