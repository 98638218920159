import React from 'react'
import ImageThumbnail from '../../atoms/thumbnails/ImageThumbnail/ImageThumbnail'
import { addressEditIcon, addressMapIcon } from '../../../assets/icons'
import Styles from "./AddressCard.module.scss";
import { CardTitle } from '../../atoms/text/CardTitle';
import { Stack } from '@mui/material';
import Text from '../../atoms/text/Text';
import InfoCard from '../../atoms/Card/InfoCard/InfoCard';
import CRadio from '../../atoms/Radio/Radio';

function AddressCard(props) {
    const { item, edit, selectedAddress, onAddressChange } = props;
    return (
        <div className={Styles.cardContainer}>
            <div className={Styles.card}>

                <div className={Styles.leftsideContent}>
                    <div className={Styles.addressIconSize}>
                        <ImageThumbnail src={addressMapIcon} />
                    </div>

                    {
                        // Object.keys(item).length > 0 ?
                        item?.address !== "" && item?.address ?
                            <Stack justifyContent={"space-between"} gap="6px" overflow={"hidden"}>
                                <Stack direction={"row"} spacing={1}>
                                    <CardTitle>{item.address}</CardTitle>
                                    {/* {
                                        selectedAddress?.name === item.name &&
                                        <InfoCard>Default</InfoCard>
                                    } */}

                                </Stack>
                                <Text className={Styles.desc}>{item.user_area}</Text>
                            </Stack>
                            :
                            "Add Address"
                    }
                    {/* <Stack justifyContent={"space-between"} gap="6px">
                        <Stack direction={"row"} spacing={1}>
                            <CardTitle>{item.address}</CardTitle>
                            {
                                selectedAddress?.name === item.name &&
                                <InfoCard>Default</InfoCard>
                            }

                        </Stack>
                        <Text className={Styles.desc}>{item.user_area}</Text>
                    </Stack> */}
                </div>


                {
                    edit &&
                    <div className={Styles.editIconSize} onClick={edit}>
                        <ImageThumbnail src={addressEditIcon} />
                    </div>
                }

                {
                    onAddressChange &&
                    <CRadio
                        item={item}
                        name="address"
                        selectedAddress={selectedAddress}
                        onAddressChange={onAddressChange}
                    />
                }


            </div>
        </div>
    )
}

export default React.memo(AddressCard);