import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'; // Import stepConnectorClasses
import Styles from "./Steeper.module.scss";
import { trackingIcon1, trackingIcon2, trackingIcon3, trackingIcon4 } from '../../../assets/icons';

import Lottie from "lottie-react";
import deliveryAnimation from "../../../assets/lottiIcons/truck-delivery.json"
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    top: "8px",
    left: "calc(-50% + 0.67vw)",
    right: "calc(50% + 0.7vw)",
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        // top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    // [`&.${stepConnectorClasses.completed}`]: {
    //     [`& .${stepConnectorClasses.line}`]: {
    //         backgroundImage:
    //             'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    //     },
    // },
    [`& .${stepConnectorClasses.line}`]: {

        // height: "0.1vw",
        border: "1px dashed #212121",
        // backgroundColor:"red",
        // borderRadius: 1,

    },
}));
const CustomStepLabel = styled(StepLabel)({
    marginTop: '0px',
    ".MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        marginTop: '0px',
    }
});
const CustomizedSteppers = (props) => {
    const { active } = props
    const steps = [
        {
            title: "Order Placed",
            status: "initial",
            icon: trackingIcon1
        },
        {
            title: "Pending",
            status: "pending",
            icon: trackingIcon2
        },
        {
            title: "Ready",
            status: "ready",
            icon: trackingIcon3
        },
        {
            title: "Delivery",
            status: "delivered_by_agent",
            icon: trackingIcon4
        }
    ]
   
    const currentindex = steps.findIndex((item) => item?.status === active?.toLowerCase())
    return (
        <Stepper alternativeLabel connector={<ColorlibConnector activeStep={3} />}
            className={Styles.container1}>
            {steps.map((item, index) => (
                <Step key={item.title} >
                    <CustomStepLabel StepIconComponent={() => (<ColorlibStepIcon active={index <= currentindex} item={item} />)} >
                        <div className={Styles.topContent}>
                            <img src={item.icon} />
                            {/* <Lottie
                                height={60}
                                width={60}
                                animationData={deliveryAnimation} loop={true} /> */}
                        </div>

                    </CustomStepLabel>
                </Step>
            ))}
        </Stepper>

    );
};

const ColorlibStepIcon = ({ active, item }) => {
 
    return (
        <div
            style={{
                width: "16px",
                height: "16px",
                borderRadius: '50%',
                border: "1px solid grey",
                backgroundColor: "grey",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                zIndex: 1,
                fontSize: "12px"
            }}
        >
            <span className={active && Styles.active}>&#10003;</span>
        </div>
    );
};

export default CustomizedSteppers;
