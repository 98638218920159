import React from "react";

import { FooterCompoment } from "../../components/layout/footer/Index";
import { TopNavComponent } from "../../components/layout/header/Index";
import ProductDetailsPage from "../../components/productDetailsPage/ProductDetailPage";
import PageHeaderComponent from "../../components/shared/PageHeaderComponent/PageHeaderComponent";

export default function ProductDetail() {
  return (
    <React.Fragment>
      <div className=" padding2">
        <TopNavComponent />

        <PageHeaderComponent back={true} title="Product Details" />

        <ProductDetailsPage />

        <FooterCompoment />
      </div>
    </React.Fragment>
  );
}
