import React from 'react'
import Text from '../../components/atoms/text/Text'
import Styles from "./SectionWise.module.scss";
import { Stack } from '@mui/material';
export function SectionWise(props) {
    const { title, desc1, desc2 } = props
    return (
        <Stack spacing={1}>
            <Text className={Styles.title}>{title} :</Text>
            <Text className={Styles.desc}>{desc1}</Text>
            <Text className={Styles.desc}>{desc2}</Text>
        </Stack>
    )
}

