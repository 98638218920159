import React from 'react'

function CDivider() {
    return (
        <div style={{
            width: "100%",
            borderBottom: "2px solid #EEEEEE",
        }} />
    )
}

export default CDivider