import React, { useEffect, useState } from 'react'
import { TopNavComponent } from '../../components/layout/header/Index'
import { FooterCompoment } from '../../components/layout/footer/Index'
import { useDispatch, useSelector } from 'react-redux';
import { useProductOrderApiMutation } from '../../store/slice';
import { emptyCartItems } from '../../store/reducers/Index';
import { LoaderCircle } from '../../components/atoms/Skeletons/SkeletonProduct';
import { Stack } from '@mui/material';
import Text from '../../components/atoms/text/Text';
import OrderSuccess from '../../components/shared/orderSuccess/Index';
import CustomeModal from '../../components/atoms/Modal/CustomeModal';
import AddNewAddress1 from '../../components/molecules/AddressCard/AddNewAddress1';
import { useRef } from 'react';

export function CheckoutProccessing() {
    const [productorderApi, { isSuccess: orderPlacedSuccessApi }] = useProductOrderApiMutation();
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.products.cart);
    const userData = useSelector((state) => state.products.userData)

    const [confirm, setConfirm] = useState(false)
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const hasCalledApi = useRef(false);  

    const [openAddressList, setOpenAddressList] = useState(false)

    const vals = cart.map((idx) => idx.item_price * idx.count);

    let totalPrice = 0;
    for (let i = 0; i < vals.length; i++) {
        totalPrice = totalPrice + Number(vals[i])
    }

    const openAddressPopup = () => {
        setOpenAddressList(!openAddressList)
    }
    const closeAddressPopup = () => {
        setOpenAddressList(false)

    }
    const checkOut = async () => {
        if (hasCalledApi.current) return;  
        hasCalledApi.current = true;
        const formdata = new FormData();
        console.log("cart ",cart); 
        formdata.append("item_name", cart.map((item) => item.item_name));
        formdata.append("item_id", cart.map((item) => item.sno));
        formdata.append("item_quantity", cart.map((item) => item.count));
        formdata.append("item_price", cart.map((item) => item.item_price));
        formdata.append("address", `${userData?.address}, ${userData?.user_area}`);
        formdata.append("shop_id", cart.map((item) => item.shop_id));
        formdata.append("net_total", totalPrice);
        formdata.append("payment_type", "cash");
        formdata.append("delivery_type", "");

        formdata.append("user_mobile", userData?.mobile_number);
        formdata.append("user_lat", "17.4375084");
        formdata.append("user_lon", "78.4482441");
        formdata.append("vendor_lat", "55");
        formdata.append("vendor_lon", "55");
        formdata.append("delivery_fee", "70");
        if (userData?.address) {
            const res = await productorderApi(formdata)
            if (res.error.data.trim() === "ok") {
                dispatch(emptyCartItems())
                setConfirm(true)
                setLoading(false)
            }
        } else {
            openAddressPopup()
        }

    }

    useEffect(() => {
        setCount(1)
        if (count === 0 && cart.length > 0 && userData?.mobile_number) {
            checkOut()
        }
    }, [])
    return (
        <div className='padding2'>
            {
                loading ?
                    <Stack sx={{
                        height: "100vh",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 4
                    }}>
                        <Text>Checkout proceesing</Text>
                        <LoaderCircle />
                    </Stack>
                    :
                    <OrderSuccess props={{ confirm, setConfirm }} />
            }

            {
                <CustomeModal title="Add Address" outSide={false} show={openAddressList} setShow={setOpenAddressList}>
                    <AddNewAddress1 close={closeAddressPopup} profileData={userData} />
                </CustomeModal>
            }
        </div>
    )
}