import React from 'react'
import Styles from "./PageHeaderComponent.module.scss";
import { FiSearch } from 'react-icons/fi';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { backIcon, headerIcon, moreIcon } from '../../../assets/icons';
function PageHeaderComponent(props) {
    const { back = false, title, search, more } = props
    const navigate = useNavigate()
    return (
        <div className={Styles.container}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap="16px"
                overflow={"hidden"}
            >
                {
                    back ? <div
                        style={{ backgroundImage: `url(${backIcon})` }}
                        onClick={() => navigate(-1)}
                        className={Styles.backIcon}
                    />
                        :
                        <div
                            style={{ backgroundImage: `url(${headerIcon})` }}
                            className={Styles.icon}
                        />
                }

                <div className={Styles.title}>{title}</div>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} gap="20px">

                {
                    search && <FiSearch className={Styles.searchIcon} />
                }

                {
                    more &&
                    <div
                        style={{ backgroundImage: `url(${moreIcon})` }}
                        className={Styles.moreIcon}
                    />
                }

            </Stack>

        </div>
    )
}

export default PageHeaderComponent