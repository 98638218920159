import { Grid, Stack } from "@mui/material";
import Styles from "./CategoryMixture.module.css";
import SquareCard from "../SquareCard/SquareCard";
import RectangleCard from "../RectangleCard/RectangleCard";

export default function CategoryMixture({ data, flexDirection }) {
  const { category1, category2, category3 } = data;
  return (
  
    <Grid
      container
      spacing={2}
      flexDirection={{ xs: flexDirection, sm: "row" }}
    >
      <Grid item xs={6}>
        <SquareCard data={category1} />
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2}>
          <RectangleCard data={category2} />
          <RectangleCard data={category3} />
        </Stack>
      </Grid>
    </Grid>
 
  );
}
