import React from "react";

function Text(props: any) {
  return (
    <div
      onClick={props.onClick}
      className={props.className}
      style={{color:props.color, fontWeight:props.weight}}
    >
      {props.children}
    </div>
  );
}

export default Text;
