import React from 'react'
import { TopNavComponent } from '../../components/layout/header/Index'
import ShopCategoriesGrid from '../../components/pages/ShopCategoriesGrid/ShopCategoriesGrid'
import { FooterCompoment } from '../../components/layout/footer/Index'
import PageHeaderComponent from '../../components/shared/PageHeaderComponent/PageHeaderComponent'
import {  useParams } from 'react-router-dom'

function ShopCategoryPage() {
    const { categoryName } = useParams()

    return (
        <div className={`padding2`}>
            <TopNavComponent />
            <section className={`backBtnSticky`}>
                <PageHeaderComponent back={true}
                    title={` ${categoryName}`} />
            </section>
            <ShopCategoriesGrid />
            <FooterCompoment />

        </div>
    )
}

export default ShopCategoryPage