import React, { useState } from "react";
import { FooterCompoment } from "../../components/layout/footer/Index";
import { TopNavComponent } from "../../components/layout/header/Index";
import { HeaderCompoment, } from "../../components/pages/orders/Index";
import OrdersPage from "../../components/molecules/Orders/OnGoing/OnGoing";
import CustomeModal from "../../components/atoms/Modal/CustomeModal";
import ProductReview from "../../components/molecules/ProductReview/ProductReview";
import ProductTracking from "../../components/molecules/ProductTracking/ProductTracking";
import PageHeaderComponent from "../../components/shared/PageHeaderComponent/PageHeaderComponent";

export default function Index() {


  const [tab, setTab] = useState("Ongoing");

  const [openReview, setOpenReview] = useState(false);
  const [openTrack, setOpentrack] = useState(false);
  const [getProduct, setGetProduct] = useState({})

  const props = {
    tab,
    setTab,
  };

  return (
    <React.Fragment>
      <div className="padding2" >
        <TopNavComponent />

        <PageHeaderComponent title="My Orders" more={true} search={true} />

        <HeaderCompoment {...props} />

        <OrdersPage
          tab={tab}
          setReview={setOpenReview}
          setTrack={setOpentrack}
          setGetProduct={setGetProduct}
        />

        {
          // openReview &&
          <CustomeModal title="Review" show={openReview} setShow={setOpenReview}>
            <ProductReview item={getProduct} cancel={setOpenReview} />
          </CustomeModal>
        }
        {
          // openTrack &&s
          <CustomeModal title="Tracking Details" show={openTrack} setShow={setOpentrack}>
            <ProductTracking item={getProduct} />
          </CustomeModal>
        }


      </div>
      <FooterCompoment />
    </React.Fragment>
  );
}
