import React from 'react'
import Styles from "./productCard.module.scss";

function ProductCard(props) {
    const {children}=props
    return (
        <div className={Styles.productCardContainer}>
            {children}
        </div>
    )
}

export default ProductCard