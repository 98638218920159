import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseUrlWholphin = "https://wholphintech.com/werp/main/server/";

const baseUrlOrderRift = "https://wholphintech.com/new_orderRift/server_api"

let formData1 = new FormData();
formData1.append('user_mobile', "919700707651");
formData1.append('user_password', "123123");

const formDataToUrlParams = (formData) => {
  const params = new URLSearchParams();
  formData.forEach((value, key) => {
    params.append(key, value);
  });
  return params.toString();
};



export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlOrderRift,
    // headers: { 'content-type': 'application/json' },
    // prepareHeaders: (headers) => {
    //   headers.set("Content-Type", "multipart/form-data");
    //   return headers;
    // },
  }),
  tagTypes: ["orders", "profile", "shops"],
  endpoints: (builder) => ({



    loginRequest: builder.mutation({
      query: (payload) => {
        return {
          url: `/otp.php`,
          method: "POST",
          type: "POST",
          body: payload,
        };
      },
      
    }),
    getOTP: builder.mutation({
      query: (payload) => {
        return {
          url: `/users.php`,
          method: "POST",
          type: "POST",
          body: payload,
        };
      },
      providesTags: ["orders", "profile", "shops"]
    }),


    productOrderApi: builder.mutation({
      query: (payload) => {
        return {
          url: `/add_order_details.php`,
          method: "POST",
          body: payload
        }
      },
      invalidatesTags: ["orders"]
    }),

    // userOrdersApi: builder.mutation({
    //   query: (payload) => {
    //     return {
    //       url: `/get_user_order_history.php`,
    //       method: "POST",
    //       body: payload
    //     }
    //   },
    //   invalidatesTags: [ "orders"]
    // }),
    userOrdersApiQuery: builder.query({
      query: (payload) => {
        return {
          url: `/get_user_order_history.php`,
          method: "POST",
          body: payload
        }
      },
      providesTags: ["orders"]
    }),

    userProfile: builder.mutation({
      query: (payload) => {
        return {
          url: `/profile.php`,
          method: "POST",
          body: payload
        }
      },
      invalidatesTags: ["profile"]
    }),

    userProfileUpdate: builder.mutation({
      query: (payload) => {
        return {
          url: `/update_profile_edit.php`,
          method: "POST",
          body: payload
        }
      },
      invalidatesTags: ["profile"]
    }),



    getNearByShops: builder.query({
      query: (payload1) => {
        let payload = new FormData()
        payload.append("lat_maps", "17.4375084")
        payload.append("lon_maps", "78.4482441")
        return {
          url: `/mobile_user/home-page/get_nearby_shops_list_from_city_center.php`,
          method: "POST",
          body: payload
        }
      },
      providesTags: ["shops"]
    }),


    getAllProducts: builder.query({
      query: () => {
        return {
          url: `/get_items2.php`,
          method: "GET",
        }
      }
    })


  }),
});
export const {


  useGetOTPMutation,
  useLoginRequestMutation,
  useProductOrderApiMutation,
  useUserProfileMutation,
  useUserProfileUpdateMutation,
  useGetNearByShopsQuery,
  useGetAllProductsQuery,
  
  // useUserOrdersApiMutation,
  useUserOrdersApiQueryQuery

} = apiSlice;
