
import { ArrowBack, } from "@mui/icons-material";
import { Box, Grid, Stack, } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from "react-redux";
import Styles from "./page.module.scss";
import Text from "../../atoms/text/Text";
import { useRef, useState } from "react";
import OTPInput from "react-otp-input";
import ErrorText from "../../atoms/text/Errortext";
import Toast from "../../atoms/Toast/Toast";
import Input from "../../atoms/Input/Input";
import LabelText from "../../atoms/text/LabelText";
import { useGetOTPMutation, useLoginRequestMutation, useUserProfileMutation } from "../../../store/slice";
import { useNavigate } from "react-router-dom";
import { setLoginStatus, setupcomingPath, setUserProfileData } from "../../../store/reducers/Index";
import WholphinLogo from "../../wholphinLogo/WholphinLogo";
import MButton from "../../atoms/button/MButton";


export default function LoginScreen() {
  const [sendOTP, { isLoading: isSendingOTP }] = useGetOTPMutation();
  const [getOTP, { isLoading: isGettingOTP }] = useLoginRequestMutation();
  const [getUserProfileApi] = useUserProfileMutation()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [otp, setOtp] = useState("");

  const [showOtp, setShowOtp] = useState(false);
  const [showToast, setShowToast] = useState(false);
 
  const [mobileErr, setMobileErr] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const mobileNumberRef = useRef()

  const upcomingUrlPath = useSelector((state) => state.products.upcomingPath);

  const validateMobile = () => {
    if (!(mobileNumberRef?.current?.value?.length === 10)) {
      setMobileErr("Enter valid mobile number");
      return false;
    }

    return true;
  };

  const validateOtp = () => {
    if (!(otp.length === 6)) {
      setOtpErr("Enter valid OTP");
      return false;
    }
    return true;
  };



  const handleGetOTP = async () => {
    if (!validateMobile()) return;
    setMobileErr("");
    const payload = new FormData();
    payload.append("mobile", mobileNumberRef?.current?.value);

    const res = await getOTP(payload);
    console.log("getOTP res", res);
    if (res.error?.data?.trim() == "ok") {
      setShowOtp(true);
      setShowToast(true);
    }
  };

  const handleLogin = async () => {
    if (validateMobile() && !showOtp) {
      setShowOtp(true);
    }

    if (!validateOtp() && validateMobile()) return;

    setOtpErr("");


    const payload = new FormData();

    payload.append("mobile", mobileNumberRef?.current?.value);
    payload.append("otp", otp);

    const res = await sendOTP(payload);
    if (res.error?.data?.trim() == "ok") {
      const getUserPayload = new FormData()
      getUserPayload.append("id", mobileNumberRef?.current?.value)
      const userDataRes = await getUserProfileApi(getUserPayload)
      console.log("userDataRes", userDataRes);
      dispatch(setUserProfileData(userDataRes?.data && userDataRes?.data[0]))
      navigate(upcomingUrlPath)
      dispatch(setLoginStatus(true));
      setShowOtp(false);
      dispatch(setupcomingPath("/"))
    }
  };

  const backBtn = () => {
    navigate(-1)
  }

  return (
    <>
      <Grid container className={Styles.container}>
        <Grid
          item
          py="2rem"
          px={{ xs: "32px", sm: "44px", md: "96px", lg: "156px" }}
          xs={12}
          sm={5}
          bgcolor="var(--black)"
          height={{ xs: "40vh", sm: "100vh" }}
          position={{ sm: "sticky" }}
          top={0}
        >
          <div className={Styles.backIcon} onClick={backBtn}>
            <ArrowBack style={{ color: "#fff" }} />
          </div>
          <Stack
            justifyContent={{ xs: "center", sm: "space-between" }}
            height="100%"
          >
            <div className={Styles.logoImgBox}>
              <WholphinLogo />
            </div>

            <Box>
              <Stack spacing={2}>
                <Text className={Styles.bld2Xl} color="#fff">
                  <span style={{ color: "#fbd914" }}> Login </span>to your Phin Store
                  account
                </Text>
                {/* <Text className={Styles.txt} color="#fff">
                  Too many passwords? You can now login with an OTP we will send
                  on your email address or verified mobile number.
                </Text>
                <Text className={Styles.txt} color="#fff">
                  Access your Phin Store account using your email address to add and
                  verify your mobile number.
                </Text> */}
              </Stack>
            </Box>
            <div className={Styles.footer}>
              <Text className={Styles.txtSm} color="#fff">
                Phin Store - Cookie Policy and Privacy Policy{" "}
              </Text>
              <Text className={Styles.txtSm} color="#fff">
                © Inter Phin Store Systems B.V. 1999-2024
              </Text>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={7} mt={{ xs: 4, sm: 0 }} px={{ xs: 4, md: 12 }}>
          <Stack justifyContent="center" height="100%">
            <Box maxWidth="28rem">
              <Box>
                <Stack spacing={2.5} mb={1}>
                  {/* {!showOtp && ( */}
                  <Stack spacing={0.5}>
                    <LabelText>Mobile Number</LabelText>

                    <Input
                      ref={mobileNumberRef}
                     

                    // onChange={handleMobileChange}
                    />
                    {mobileErr && <ErrorText errorText={mobileErr} />}
                    <div onClick={handleGetOTP}>
                      <Text className={Styles.txt}>
                        Login{" "}
                        <a
                          role="button"
                          className={Styles.linkBtn}
                          color="#484848"
                        >
                          with an otp
                        </a>
                      </Text>
                    </div>
                  </Stack>
                  {/* )} */}
                  {showOtp && (
                    <Stack spacing={3} justifyContent="center">

                      <div>
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          inputType="tel"
                          
                          containerStyle={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                          inputStyle={{
                            width: "3rem",
                            height: "3rem",
                            borderRadius: "4px",
                            border: "1px solid gray",
                          }}
                          // renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          mt={1}
                        >
                          <Box>
                            {otpErr && <ErrorText errorText={otpErr} />}
                          </Box>
                          <Box>
                            <a
                              className={Styles.linkBtn}
                              role="button"
                              onClick={handleGetOTP}
                            >
                              Send new Otp
                            </a>
                          </Box>
                        </Stack>
                      </div>
                    </Stack>
                  )}
                </Stack>
              </Box>
              <Stack spacing={3} mt={4}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Checkbox
                    sx={{
                      // color: "#0058a3",
                      width: "1.75rem",
                      height: "1.75rem",
                    }}
                  />
                  <Text className={Styles.txt}>
                    Stay signed in until you sign out
                  </Text>
                </Stack>
                <Box width="100%" overflow="hidden">
                  <MButton disabled={isSendingOTP}
                    onClick={handleLogin} >
                    <div className={Styles.bld} style={{ color: "#fff" }}>
                      {isSendingOTP ? "Loading..." : "Continue"}
                      {/* Continue */}
                    </div>
                  </MButton>
                </Box>
              </Stack>

              {/* <Box mt={2} textAlign="center">
                <Text className={Styles.bldLg} color="#484848">
                  Do not have an Phin Store account yet? Create one now:
                </Text>
              </Box>

              <Stack spacing={2} mt={4}>

                <MButton variant="outline">
                  <div className={Styles.bld} style={{ color: "black" }}>
                    I am buying for my home
                  </div>
                </MButton>
                <MButton variant="outline">
                  <div className={Styles.bld} style={{ color: "black" }}>
                    I am buying for my company
                  </div>
                </MButton>

              </Stack> */}
            </Box>
            <div className={Styles.footer2}>
              <Text className={Styles.txtSm} color="#484848">
                Phin Store - Cookie Policy and Privacy Policy{" "}
              </Text>
              <Text className={Styles.txtSm} color="#484848">
                © Inter Phin Store Systems B.V. 1999-2024
              </Text>
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Toast
        isOpen={showToast}
        toastMsg="If your mobile number is verified with Phin Store, you will recieve an OTP"
        SetIsToastOpen={setShowToast}
        toastType="success"
      />
    </>
  );
}
