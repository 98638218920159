
import React from "react";
import Styles from "./baseButton.module.scss";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const CustomeButton = styled(Button)({
  borderRadius: "4px",
  textTransform: "capitalize",
  "&.Mui.disabled": {
    background: "grey",
  },
  "&:hover": {
    background: "rgb(9, 88, 217)",
    // background: "red !important",
    // color:"currentColor"
  },
});

function BaseButton(props: any) {
  const btnStyle = {
    background: props.disabled
      ? "#26262614"
      : props.bgColor
      ? props.bgColor
      : "transparent",
    color: props.disabled ? "#bfbfbf" : "",
    // cursor: props.disabled ? "not-allowed" : "pointer",
  };

  return (
    <CustomeButton
      {...props}
      className={`${Styles.container} ${props.className}`}
      style={btnStyle}
    >
      {props.children}
    </CustomeButton>
  );
}

export default BaseButton;
