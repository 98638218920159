import React from "react";
import {
  MostPopularCompoment,
  SeeMore,
} from "../../components/pages/home/app";
import { FooterCompoment } from "../../components/layout/footer/Index";
import { TopNavComponent } from "../../components/layout/header/Index";
import HeaderTabsComponent from "../../components/headerTabsComponent/headerTabsComponent";
import MobileHeaderSection from "../../components/layout/mobileHeaderSection/page";
import { Stack } from "@mui/material";
import HomePageCategories from "../../components/HomePageCategoryGrid/HomePageCategories";
import GetNearByShops1 from "../../components/GetNearByShops/GetNearByShops1";
import { ProdcutsOffersSection } from "../../components/productsOffers/prodcutsOffersSection";
import PageOffersSection from "../../components/pageOffersSection/PageOffersSection";

export default function Home() {
  return (
    <React.Fragment >
      <div className="animate padding2">
        <TopNavComponent />
        <Stack rowGap={5} mt={4}>
          <MobileHeaderSection />
          {/* <HeaderTabsComponent /> */}
          <PageOffersSection />
          <ProdcutsOffersSection />
          <HomePageCategories />
          <GetNearByShops1 />
          
          <SeeMore />

          <MostPopularCompoment />
        </Stack>


        <FooterCompoment />
      </div>
    </React.Fragment>
  );
}
