import React from 'react'
import CDivider from '../atoms/Divider/Divider'
import Styles from "./Logout.module.scss";
import Text from '../atoms/text/Text';
import MButton from '../atoms/button/MButton';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { emptyCartItems, setLoginStatus, setUserProfileData } from '../../store/reducers/Index';
function LogoutComponent(props) {
    const { cancel } = props

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const cancelBtn = () => {
        cancel(false)
    }
    const logoutBtn = () => {
        dispatch(setUserProfileData({}))
        dispatch(setLoginStatus(false));
        dispatch(emptyCartItems())
        navigate("/")

    }
    return (
        <div className={Styles.container}>
            <Stack spacing={1} mb={1}>
                <div className={Styles.title}>Logout</div>
                <CDivider />
            </Stack>
            <div className={Styles.bodyContent}>
                <Text className={Styles.text}>Are you sure want to log out?</Text>
                <Stack direction="row" justifyContent={"space-between"} gap="12px">
                    <MButton
                        fullWidth variant="outline"
                        size="small" height="auto"
                        onClick={cancelBtn}
                    >
                        Cancel
                    </MButton>
                    <MButton
                        fullWidth size="small" height="auto"
                        onClick={logoutBtn}
                    >Logout</MButton>
                </Stack>
            </div>
        </div>
    )
}

export default React.memo(LogoutComponent)