import React from 'react'
import { IconButton, styled } from '@mui/material'
function CIconButton(props) {

    const CustomeIconBtn=styled(IconButton)({
        backgroundColor:"#fff",
        boxShadow:"0px 0px 2px black",
        height:"36px",
        width:"36px",
        // color: black;
        // background-color: rgb(255, 255, 255);
        // border-radius: 999px;

        // "&.Mui.disabled": {
        //     // background: "red",
        //   },
          "&:hover": {
            background: "#fff",
            // background: "red !important",
            // color:"currentColor"
          },
        //  ".MuiTouchRipple-child": {
        //     backgroundColor: "red",
        // }
    })
    return (
        <CustomeIconBtn {...props} >
            {props.children}
        </CustomeIconBtn>
    )
}

export default CIconButton