import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { apiSlice } from "./slice";
import productsReducer from "./reducers/Index";

const rootReducer = combineReducers({
  products: productsReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
})
// export const store = configureStore({
//   reducer: {

//     products: productsReducer,
//     [apiSlice.reducerPath]: apiSlice.reducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(apiSlice.middleware),
// });
// setupListeners(store.dispatch);
const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

const persistor = persistStore(store);

export { store, persistor };