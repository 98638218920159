//final
import React from 'react'
import Styles from "./CartImage.module.scss";
import { productsImageUrl } from '../../../config/config';

export default function CartImage(props) {
    const {imageUrl}=props
    return (
        <div className={Styles.cartImageContainer}>
            <div
                style={{ backgroundImage: `url(${productsImageUrl + imageUrl})`, }}
                className={Styles.image}
            />
        </div>
    )
}


