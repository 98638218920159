import React from 'react'
import { LoadScript } from '@react-google-maps/api';
const libraries = ["places", "maps", "marker", "core"]
function MapsKeyWrapper({ children }) {
    return (
        <LoadScript googleMapsApiKey="AIzaSyB2gIWgH0pXIdx8NWNi9IfOMQ_2rSvzGMs" libraries={libraries}>
            {children}
        </LoadScript>
    )
}

export default MapsKeyWrapper