import React,{useEffect, useState} from 'react'; 
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

 function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

  const Toast = ({isOpen,toastMsg ,SetIsToastOpen,toastType})=> {
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  
  const handleClick = (Transition) =>   { 
    setTransition(() => Transition);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  useEffect(()=>{ 
    if(isOpen === true){ 
        handleClick(TransitionUp);
        const myTimeout= setTimeout (()=>{
            handleClose();
            SetIsToastOpen(false);
        },3000) 
    } 
  },[isOpen])
  return ( 
     
            <Snackbar 
            open={open}
            onClose={handleClose}
            TransitionComponent={transition} 
            key={transition ? transition.name : ''}
         >
             <Alert onClose={handleClose}   severity={toastType} sx={{ width: '100%' }}>
               {toastMsg}
            </Alert>
            </Snackbar>
   
    
  );
}
export default Toast;
