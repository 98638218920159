import { Box, Grid, Stack } from '@mui/material'
import React from 'react'
import ProductCard from '../../components/shared/productCard/Index'
import { useGetAllProductsQuery, useGetNearByShopsQuery, } from '../../store/slice'
import { TopNavComponent } from '../../components/layout/header/Index'
import { FooterCompoment } from '../../components/layout/footer/Index'
import PageHeaderComponent from '../../components/shared/PageHeaderComponent/PageHeaderComponent'
import { useParams } from 'react-router-dom'
import SkeletonProduct from '../../components/atoms/Skeletons/SkeletonProduct'
import Styles from "./ProductsByShopId.module.scss";
import PageOffersSection from '../../components/pageOffersSection/PageOffersSection'
import { ProdcutsOffersSection } from '../../components/productsOffers/prodcutsOffersSection'
import { CategoryOffersSection } from '../../components/offersSections/CategoryOffersSection/CategoryOffersSection'
import Filters from '../../components/filters/Filters'
function ProductsByShopId() {
    const { shopId } = useParams()
    const { data: getAllProducts1, isLoading: statusLoading, isSuccess: apiCallSuccess } = useGetAllProductsQuery()
    const { data: nearByshopsApi } = useGetNearByShopsQuery()


    const getAllProducts = getAllProducts1?.filter((item) => item.shop_id === shopId) || []
    const shopName = nearByshopsApi?.find((item) => item?.shop_id === shopId)

    //random index numers not shows (use state variable)
    let randomProducts = [];

    if (getAllProducts?.length > 20) {
        for (let i = 0; i < 20; i++) {
            const random = Math.floor(Math.random() * getAllProducts?.length)
            if (!randomProducts?.includes(random)) {
                randomProducts = [...randomProducts, getAllProducts[random]]
            }
            else {
                i = i - 1
            }
        }
    } else {
        randomProducts = [...getAllProducts]
    }

    return (
        <div className={`${Styles.pageContainer} `}>
            <div
            //  className='padding2'
              className={`${Styles.padding2} `}>

                <TopNavComponent />
                
                <section className={`backBtnSticky`} style={{paddingLeft:"8px"}}>
                    <PageHeaderComponent
                        back={true}
                        title={`${shopName?.name}`}
                    />
                </section>

                <Stack
                    mt={-1}
                    rowGap={6}
                // spacing={4}
                >
                    <Stack rowGap={4} className={Styles.bg_color}>
                        <PageOffersSection shopName={shopName?.name} />

                        {/* <ProdcutsOffersSection list={randomProducts} /> */}
                        <CategoryOffersSection />
                    </Stack>
                    <section className={Styles.bg_color}>
                        {/* <CategoryOffersSection /> */}
                        <ProdcutsOffersSection list={randomProducts} />
                    </section>

                    <section className={Styles.bg_color}>
                        <Filters productsList={getAllProducts} />
                    </section>

                    {/* <Grid container spacing={{ xs: 2, sm: 3, md: 4 }} >
                    {
                        apiCallSuccess ?
                            getAllProducts?.map((item) => {
                                return (
                                    <Grid key={item.sno} item xs={6} sm={4} md={3} lg={3}>
                                        <ProductCard props={item} />
                                    </Grid>
                                )
                            })
                            :
                            [...Array(15)].map((_, index) => {
                                return (
                                    <Grid key={index} item xs={6} sm={4} md={3} lg={3}>
                                        <SkeletonProduct />
                                    </Grid>
                                )
                            })
                    }
                </Grid> */}

                </Stack>

                <FooterCompoment />
            </div>
        </div>
    )
}

export default ProductsByShopId