import styles from "./index.module.scss";
import ProductCard from "../../shared/productCard/Index";
import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";

import { categoryListItems } from "./data";
import MButton from "../../atoms/button/MButton";

import { useGetAllProductsQuery } from "../../../store/slice";



export const MostPopularCompoment = () => {
  const { data: products } = useGetAllProductsQuery()
  const [inputValue, setInputValue] = useState("");
  const [itemsToShow, setItemsToShow] = useState(50);

  const moreBtn = (() => setItemsToShow((prev) => prev + 50))

  const filteredProducts =
    // products?.find((item) => item.shop_category?.toLowerCase() === inputValue?.url) ?
    inputValue ?
      // products?.filter((item) => item?.shop_category?.toLowerCase() === inputValue?.url)
      products?.filter((item) => item?.item_category?.toLowerCase()?.includes(inputValue?.url))
      // item.item_category?.toLowerCase()?.includes(categoryName)
      : products
    ;
const checkingImge=filteredProducts?.filter((item) =>  !item.item_image=="" )
  const loadItems = checkingImge?.filter((item, index) =>  index < itemsToShow )
console.log("loadItems", loadItems);
  return (
    <div className={styles.MostPopularCompoment}>

      <Box mb={3} sx={{
        position: "sticky",
        padding: { xs: "22px 0", sm: "8px 0" },
        top: { xs: "0px", sm: "90px", },
        background: "white",
        zIndex:1
      }}>
        <CategoriesList getCategory={setInputValue} inputValue={inputValue} />
      </Box>


      <Grid container columnSpacing={2} rowSpacing={3}>
        {loadItems?.map((item, index) => {
          return (
            <Grid key={item?.sno} item xs={6} sm={4} md={3} lg={3}  >
              <ProductCard props={item} />
            </Grid>
          )
        })}

      </Grid>
      <Grid item xs={12}>
        <Stack alignItems={"flex-end"} py={2}>
          {
            filteredProducts?.length > itemsToShow &&
            <MButton onClick={moreBtn} height="38px" size="small" >
              See more
            </MButton>
          }
        </Stack>
      </Grid>
      {/* {
        filteredProducts?.length > itemsToShow &&
        <MButton onClick={moreBtn} height="38px" size="small" >
          See more
        </MButton>
      } */}


    </div >
  );
};


export const SeeMore = () => {
  return (
    <Stack flexDirection={"row"} justifyContent={"space-between"}>
      <div className={styles.SpecialOfferTitle}>Most Popular</div>
      <Link to="/all-products">
        <div className={styles.SpecialOfferTxt}>See All</div>
      </Link>
    </Stack>
  )
}



export const CategoriesList = ({ getCategory, inputValue }) => {

  const onClick = (item) => {
    getCategory(item)
  }
  const allBtn = () => {
    getCategory("")
  }
  return (
    <>
      <section className={styles.categoryContainer}>
        <MButton
          onClick={allBtn}
          fweight={600} height="38px" size="small"
          variant={inputValue === "" ? "primary" : "outline"}
        >All</MButton>
        {categoryListItems.map((item) => {
          return (
            <MButton
              key={item.title} fweight={600} height="38px" variant={inputValue?.url === item?.url ? "primary" : "outline"}
              onClick={() => onClick(item)}
            >{item.title}</MButton>
          )
        })}
      </section>
    </>
  )
}
