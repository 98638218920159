export const filters = [
  {
    category: "Size",
    filter: [
      { id: 1, name: "Small" },
      { id: 2, name: "Medium" },
      { id: 3, name: "Large" },
      { id: 4, name: "Extra Large" },
    ],
  },
  // {
  //   category: "Price",
  //   filter: [
  //     { id: 5, name: "100-500" },
  //     { id: 6, name: "500-1000" },
  //     { id: 7, name: "1000-1500" },
  //     { id: 8, name: "1500-2000" },
  //   ],
  // },
  // {
  //   category: "Electronics",
  //   filter: [
  //     { id: 9, name: "Television" },
  //     { id: 10, name: "Mobile" },
  //     { id: 11, name: "Laptop" },
  //     { id: 12, name: "Washing Machine" },
  //   ],
  // },
  {
    category: "Color",
    filter: [
      { id: 13, name: "Blue" },
      { id: 14, name: "Red" },
      { id: 15, name: "Yellow" },
      { id: 16, name: "Green" },
    ],
  },
  // {
  //   category: "Fashion",
  //   filter: [
  //     { id: 17, name: "Fashion" },
  //     { id: 18, name: "Footwear" },
  //     { id: 19, name: "Dress" },
  //     { id: 20, name: "Caps" },
  //   ],
  // },
];

// export const filtersItems = [
//   {
//     id: 1,
//     title: "Size",
//     value: ["small", " medium", "large"],
//   },
//   {
//     id: 2,
//     title: "Price",
//     value: ["1-100", "100-200", "200-300"],
//   },
//   {
//     id: 3,
//     title: "Category",
//     value: [
//       "Mobile",
//       "Laptops",
//       "Tablet",
//       "TV",
//       "Fashion Men",
//       "Fashiom Women",
//     ],
//   },
//   {
//     id: 3,
//     title: "Color",
//     value: ["White", "Black", "Green", "Red"],
//   },
// ];
