import React from 'react'
import CDivider from '../atoms/Divider/Divider'
import Styles from "./CancelProceedComponent.module.scss";
import Text from '../atoms/text/Text';
import MButton from '../atoms/button/MButton';
import { Stack } from '@mui/material';
function CancelProceedComponent(props) {
    const { title, desc1, desc2, proccedText, cancelBtn, proccedBtn } = props


    return (
        <div className={Styles.container}>
            <Stack spacing={1} mb={1}>
                <div className={Styles.title}>{title}</div>
                <CDivider />
            </Stack>
            <div className={Styles.bodyContent}>
                <Stack>
                    <Text className={Styles.text}>{desc1}</Text>
                    <Text className={Styles.text}>{desc2}</Text>
                </Stack>
                <Stack direction="row" justifyContent={"space-between"} gap="12px">
                    <MButton
                        fullWidth variant="outline"
                        size="small" height="auto"
                        onClick={cancelBtn}
                    >
                        Cancel
                    </MButton>
                    <MButton
                        fullWidth size="small" height="auto"
                        onClick={proccedBtn}
                    >{proccedText}</MButton>
                </Stack>
            </div>
        </div>
    )
}

export default React.memo(CancelProceedComponent)