import { Box, Grid, Stack } from '@mui/material'
import Styles from "./CategoryOffersSection.module.scss";
import Text from '../../atoms/text/Text';

import menSwear from "../../../assets/images/menSwear.png";
import womenSwear from "../../../assets/images/womenSwear.png";
import luxury from "../../../assets/images/luxuryFashion.png";
import explore from "../../../assets/images/exploreImage.png";
import homeAppliance from "../../../assets/images/homeAppliance.png";
import accessories from "../../../assets/images/Accessories.png";

const categoryList = [
    { id: 1, title: "MenSwear", discount: "35% - 70%", img: menSwear },
    { id: 2, title: "WomenSwear", discount: "25% - 80%", img: womenSwear },
    { id: 3, title: "Luxury", discount: "15% - 70%", img: luxury },
    { id: 4, title: "Accessories", discount: "Up to - 40%", img: accessories },
    { id: 5, title: "Explore", discount: "25% - 80%", img: explore },
    { id: 6, title: "Home", discount: "Up to - 60%", img: homeAppliance },
]

export function CategoryOffersSection() {
    return (
        <>
            <Grid container columnSpacing={{xs:1, sm:2}} rowSpacing={3}>

                {
                    categoryList.map((item) => {
                        return (
                            <Grid item xs={4}  md={3} lg={2}>
                                <Stack rowSpacing={1}>

                                    <div className={Styles.card}>
                                        <div
                                            className={Styles.image}
                                            // style={{
                                            //     backgroundImage: `linear-gradient(to bottom, 
                                            //     rgba(245, 246, 252, 0.52), 
                                            //     rgba(117, 19, 93, 0.73)), url(${item.img})`
                                            // }}
                                            style={{
                                                backgroundImage: `linear-gradient(to bottom, #fff, #aeabab), url(${item.img})`
                                                // backgroundImage: `url(${item.img})`
                                            }}
                                        // style={{
                                        //     backgroundImage: `linear-gradient(#fff, #000), url(${item.img})`
                                        // }}
                                        />
                                        <Text className={Styles.text2}>{item.title}</Text>

                                    </div>

                                    <Text className={Styles.text1}>{item.discount}</Text>

                                </Stack>
                            </Grid>
                        )
                    })
                }

            </Grid>
        </>
    )
}

