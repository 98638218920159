import { Stack } from '@mui/material'
import React from 'react'
import BackButton from "../backButton/BackButton"
import Text from '../text/Text'
import Styles from "./ModalTitle.module.scss";
function ModalTitle(props) {
    const { onClick, title } = props
    return (
        <section className={Styles.modalTitleContainer}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} padding={1}>
                <BackButton onClick={() => onClick(false)} />
                <Text className={Styles.modalTitle}>{title}</Text>
                <Text>{""}</Text>
            </Stack>
        </section>

    )
}

export default ModalTitle