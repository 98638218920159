import React, { Fragment } from 'react'
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import Styles from "./ProductTracking.module.scss";
import CustomizedSteppers from '../../atoms/Steeper/MuiStepper';
import GridLayout from './GridLayout';

export default function ProductTracking(props) {
    const { item } = props

    return (
        <>
            <GridLayout
                section1={item}
                section2={<StepperBox {...props} />}
                section3={<TrackCompoment {...props} />}

            />
        </>
    )
}




const StepperBox = ({ item }) => {

    return (
        <Stack alignItems={"center"} gap={2} >
            <CustomizedSteppers active={item?.order_status} />
            <div className={Styles.addressTitle}>
                Packet In Delivery
            </div>
        </Stack>
    )
}

const TrackCompoment = ({ item }) => {

    const orderStatus = [
        {
            title: "Order Placed",
            status: "initial",
            date: item?.order_placed_date,
            time: item?.order_placed_time,
            address: ""
        },
        {
            title: "Pending",
            status: "pending",
            date: item?.order_placed_date,
            time: item?.order_placed_time,
            address: ""
        },
        {
            title: "Processing",
            status: "processing",
            // date: item?.order_placed_date,
            time: item?.processing_initiated_time,
            address: ""
        },
        {
            title: "Ready",
            status: "ready",
            // date: item?.order_placed_date,
            time: item?.order_ready_time,
            address: ""
        },
        {
            title: "Delivery initiated",
            status: "delivery_initiated",
            // date: item?.order_placed_date,
            time: item?.order_ready_time,
            address: ""
        },
        {
            title: "Delivery",
            status: "delivered_by_agent",
            // date: item?.delivery_date,
            time: item?.delivered_time,
            address: ""
        },

    ]
    const currentIndex = orderStatus.findIndex((status) => status.status === item.order_status?.toLowerCase());

    return (

        <Stack key={item.id}>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
                <div className={Styles.statusTitle}>Order Status Details</div>
                <div className={Styles.addressDesc}>  {"( "} Order Id : {item.id} {" )"}</div>
            </Stack>

            <div className={Styles.statusBox}>
                {orderStatus?.map(({ title, address, date, status, time, }, index) => {
                    const isActive = index <= currentIndex;
                    const formattedDate = dayjs(date, 'DD-MM-YYYY').format('DD/MM/YYYY');
                    console.log("Formatted date", formattedDate);
                    return (
                        <Fragment key={index} >
                            {
                                <div className={`${Styles.timelineItem}`}>
                                    <div className={`${Styles.timelineCircle} ${isActive && Styles.activeColor}`}><div /></div>
                                    <div className={Styles.timelineContent}>
                                        <Stack width={"60%"}>
                                            <div className={Styles.addressTitle}>{title}</div>
                                            <div className={Styles.addressDesc}>{address}</div>
                                        </Stack>
                                        <div className={Styles.dateText}>
                                            {date && date + " / "} {time}
                                        </div>
                                    </div>
                                </div>
                            }
                        </Fragment>
                    );
                })}
            </div>
        </Stack>

    );
};

