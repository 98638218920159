import { useLocation } from "react-router-dom";
import { navs } from "../../pages/home/data";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import CheckoutComponent from "../../molecules/Checkout/CheckoutComponent";
import useAuthNavigate from "../../../hooks/useAuthNavigate";


export const FooterCompoment = ({ checkout = true }) => {
  const location = useLocation();
  const authNavigate = useAuthNavigate();

  const cart = useSelector((state) => state.products.cart);
  const orders = useSelector((state) => state.products.orders.ongoing.length);
  const count = cart.map((idx) => idx.count);
  const totalCount = count.reduce((prev, curr) => prev + curr, 0);


  return (
    <div className={styles.FooterCompoment}>

      {navs.map(({ name, icon, url }, index) => {
        return (
          <div onClick={() => authNavigate(url)}
            key={index}
            className={styles.items}
          >

            <div
              className={`${styles.icon} ${location.pathname === url && styles.activePage} `}
            >
              {index === 1 && totalCount > 0 && (
                <div
                  // className={styles.bagde}
                  className={`${styles.bagde} ${styles.annim}`}
                >{totalCount}</div>
              )}
              {index === 2 && orders > 0 && (
                <div className={styles.bagde}>{orders}</div>
              )}
              {icon}
            </div>
            <div className={`${styles.name} ${location.pathname === url && styles.activePage} `} >
              {name}
            </div>

          </div>
        );
      })}
      {
        checkout &&
        (location.pathname == "/" || location.pathname == "/cart") &&
        <CheckoutComponent />
      }

   

    </div>
  );
};
