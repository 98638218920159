import React, { useState } from 'react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Styles from "./AddressShowSingleLine.module.scss"
import { ButtonBase, Stack } from '@mui/material'
import Text from '../atoms/text/Text';
import { useSelector } from 'react-redux';
import CustomeModal from '../atoms/Modal/CustomeModal';
import CModal from '../atoms/Modal/CModal';
import AddNewAddress1 from '../molecules/AddressCard/AddNewAddress1';
function AddressShowSingleLine() {
    const userData = useSelector((state) => state.products.userData)
    const [openModal, setOpenModal] = useState(false)
    return (
        <>
            <ButtonBase sx={{ width: "100%" }} onClick={() => setOpenModal(true)} >
                {userData?.address ?
                    <Stack direction="row" alignItems="center" gap={0.5} sx={{ width: "100%" }}  >
                        <PlaceOutlinedIcon sx={{ fontSize: "16px", height: "20px", lineHeight: "normal" }} />
                        <Text className={Styles.text1}>{userData?.address},  {userData?.user_area}</Text>
                    </Stack>
                    :
                    <Stack direction="row" alignItems="center" gap={0.5} sx={{ width: "100%" }}  >
                        <PlaceOutlinedIcon sx={{ fontSize: "16px", height: "20px", lineHeight: "normal" }} />
                        <Text className={Styles.text1}>Santhi Nagar, Nellore</Text>
                    </Stack>

                }
            </ButtonBase>

            <CustomeModal title="Add Address" outSide={false} show={openModal} setShow={setOpenModal}>
                <AddNewAddress1 close={() => setOpenModal(false)} />
            </CustomeModal>


        </>
    )
}

export default AddressShowSingleLine