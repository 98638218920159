import React from 'react'
import Styles from "./heroSection.module.scss"
import { useState } from 'react'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import CIconButton from '../../atoms/IconButton/IconButton';
import { motion, useInView } from 'framer-motion';
import { Box } from '@mui/material';
import { useRef } from 'react';

function HeroSection({ images }) {
    let [imageIndex, setImageIndex] = useState(0)
    const [direction, setDirection] = useState('left'); // or 'right'

    const a = images.length - 1
    // console.log("onClick Index", a);
    const increment = () => {
        if (images.length - 2 >= imageIndex) {
            setImageIndex(imageIndex + 1)
            setDirection('left');
        }
    }
    // console.log("imageIndex", imageIndex);
    const decrement = () => {
        if (imageIndex > 0) {
            setImageIndex(imageIndex - 1)
            setDirection('right');
        }
    }


    const handleMouseOver = (e) => {
        // console.log("eeeeee", e);
        setImageIndex(e)
        // setIsHovering(true);
    };
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (
        <>

            <motion.section className={Styles.container}
              ref={ref}
                style={{
                    transform: isInView ? "none" : "translateX(-220px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s"
                }}
            >
                <div className={Styles.thumbnailContainer}>
                    <div className={Styles.thumbnailBox}>
                        {
                            images?.map((item, index) => {
                                // const encodedUrl = item.imageUrl.replace(/ /g, '%20');
                                // console.log("aaa",encodedUrl);
                                // const encodedUrl = encodeURI(item.imageUrl);
                                // console.log("Encoded URL:", encodedUrl);
                                const encodedUrl = item.imageUrl.split(' ').join('%20');

                                return (
                                    <div
                                        key={index}
                                        onClick={() => setImageIndex(index)}
                                        onMouseOver={() => handleMouseOver(index)}
                                        className={Styles.thumbnailLabel}
                                        whileHover={{ scale: 1.1 }}
                                    >
                                        <motion.div
                                            
                                            style={{ backgroundImage: `url(${encodedUrl})`, }}
                                            className={Styles.thumnailImages} />
                                        {/* <img
                                            src={item.imageUrl}
                                            className={Styles.thumnailImages}
                                        /> */}

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={Styles.heroImageContainer}>
                    {/* <Box height={"100%"}> */}
                        <motion.div
                            key={imageIndex}

                            initial={{ x: direction === 'left' ? '1%' : '-1%' }}
                            animate={{ x: 0 }}
                            // exit={{ x: direction === 'left' ? '-5%' : '5%' }}
                            transition={{ type: 'tween', duration: 0.5 }}
                            style={{ backgroundImage: `url(${images[imageIndex]?.imageUrl})` }}
                            className={Styles.heroImage} />

                        <div className={Styles.slideBtnContainer}>
                            <CIconButton sx={{background:"white"}} onClick={decrement} disabled={imageIndex === 0}>
                                <KeyboardArrowLeftOutlinedIcon />
                            </CIconButton>
                            <CIconButton sx={{background:"white"}} onClick={increment} disabled={images.length - 1 <= imageIndex}>
                                <KeyboardArrowRightOutlinedIcon />
                            </CIconButton>
                        </div>
                    {/* </Box> */}

                </div>
            </motion.section>
        </>
    )
}

export default HeroSection