import React from 'react'
import ImageThumbnail from '../../atoms/thumbnails/ImageThumbnail/ImageThumbnail';
import Text from "../../atoms/text/Text";
import Styles from "./mobileProfileSection.module.scss"
import { Box, Stack, SvgIcon } from '@mui/material'
import { heartIcon, notificationIcon, profileImg } from '../../../assets/icons';
import AddressShowSingleLine from '../../AddressShowSingleLine/AddressShowSingleLine';
function MobileProfileSection() {
    return (
        <section className={Styles.container}>
            <Stack direction="row" alignItems="center" spacing={2}  width="67%">
                <div className={Styles.profileImg}>
                    <ImageThumbnail src={profileImg} />
                </div>
                <Box sx={{ overflow:"hidden"}}>
                    <Text className={Styles.text1}>Good Morning 👋</Text>
                    <AddressShowSingleLine />
                </Box>
            </Stack>

            <Stack direction="row" spacing={2}>
                <div className={Styles.icon}><ImageThumbnail src={notificationIcon} /></div>
                <div className={Styles.icon}><ImageThumbnail src={heartIcon} /></div>
            </Stack>
        </section>
    )
}

export default MobileProfileSection