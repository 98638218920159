import Text from "../../atoms/text/Text";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { CardTitle } from "../../atoms/text/CardTitle";
import Styles from "./RectangleCard.module.css";
import MainStyles from "../Categories.module.css"

export default function RectangleCard({ data }) {
  const { name, image, url } = data;
  const navigate = useNavigate()
  const onClick = () => {
    if (url)
      navigate(`/category/${url}`)
  }
  return (
    <div onClick={onClick}>
      <motion.div className={Styles.container} whileTap={{ scale: 0.1 }}>
        <Text className={MainStyles.cardTitle}>{name}</Text>

        <div className={Styles.imageBox}>
          <div
            style={{ backgroundImage: `url(${image})` }}
            className={Styles.image}
          />

        </div>
      </motion.div>
    </div>
  );
}
