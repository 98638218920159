import React, { useRef, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete, InfoWindow, MarkerF, InfoWindowF } from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: "16px",

};

const center = {
    lat: -3.745,
    lng: -38.523,
};


const CGoogleMap1 = ({ coords,setCoords }) => {
    const [searchResult, setSearchResult] = useState('')

    const [selected, setSelected] = useState(null);

    const autocompleteRef = useRef();
    function onLoad(autocomplete) {
        console.log("mapComponent", autocomplete);
        setSearchResult(autocomplete);
    }
    function onPlaceChanged() {
        if (searchResult != null) {
            //variable to store the result
            const place = searchResult.getPlace();
            console.log('Selected Place:', place);

            // Accessing specific properties
            const name = place.name;
            const formattedAddress = place.formatted_address;
            const location = place.geometry.location;
            const lat = location.lat();
            const lng = location.lng();

            console.log('Name:', name);
            console.log('Formatted Address:', formattedAddress);
            console.log('Latitude:', lat);
            console.log('Longitude:', lng);
        } else {
            alert("Please enter text");
        }
    }





    const handleMarkerDragEnd = (e) => {
        // console.log("eeee", e.latLng.lat());
        // setSelected({
        //     lat: e.latLng.lat(),
        //     lng: e.latLng.lng(),
        // });
        setCoords((prev) => ({
            ...prev,
            lat:e.latLng.lat(),
            lng:e.latLng.lng()
        }))
    };
    return (
        <>
            {/* <LoadScript googleMapsApiKey="AIzaSyB2gIWgH0pXIdx8NWNi9IfOMQ_2rSvzGMs" libraries={libraries}>
            <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}
                ref={autocompleteRef}
            >
                <input
                    type="text"
                    placeholder="Search for Tide Information"
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                    }}
                />
            </Autocomplete>

            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={coords}
                zoom={10}
                // onLoad={onLoad}
            >
                <MarkerF
                    position={coords}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                />

              
            </GoogleMap>
        </LoadScript> */}


            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={coords}
                zoom={9}
            // onLoad={onLoad}
            >
                <MarkerF
                    position={coords}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                />


                {/* {selected && (
                    <Marker
                        position={center}
                        draggable={true}
                        onDragEnd={handleMarkerDragEnd}
                    />
                )} */}
                {/* {selected && (
                <InfoWindowF
                    position={{ lat: selected.lat, lng: selected.lng }}
                    onCloseClick={() => setSelected(null)}
                >
                    <div>
                        <h2>Selected Location</h2>
                    </div>
                </InfoWindowF>
            )} */}
            </GoogleMap>
        </>

    );
};

export default CGoogleMap1;
