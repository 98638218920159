"use client"
import { useEffect } from "react";
import Styles from "./AnimatePresence.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { IconButton } from "@mui/material";
import ZoomInMapOutlinedIcon from '@mui/icons-material/ZoomInMapOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// function CAnimatePresence(props) {
//   const { children, id } = props;
//   return (
//     <div className={Styles.mainContainer}>
//       <AnimatePresence>
//         {id && (
//           <div className={Styles.container}
//           >
//             <motion.div
//              key="modal"
//               layoutId={id}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               exit={{ opacity: 0 }}

//             >
//               <motion.div
//                key="modal"
//                 // layoutId={id}
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 exit={{ opacity: 0 }}
//               // className={Styles.container}
//               >
//                 {children}
//               </motion.div>
//             </motion.div>
//           </div>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// }
function CAnimatePresence(props) {
  const { children, id, onToggleExpand } = props;
  const overlayVariants = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        duration: 0.3,
        delayChildren: 0.4
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        duration: 0.3,
        delay: 0.4
      }
    }
  };


  const toggleBodyScroll = (scrollEnabled) => {
    document.body.style.overflow = scrollEnabled ? 'auto' : 'hidden';
  };

  useEffect(() => {
    toggleBodyScroll(false);
    return () => {
      toggleBodyScroll(true);
    };
  }, []);
  const handleClick = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up
    onToggleExpand()
  };
  return (

    // <AnimatePresence>
    //   {id && (
    //     <motion.div
    //       // initial="hidden"
    //       // animate="visible"
    //       // exit="hidden"
    //       // initial={{ opacity: 0 }}
    //       // animate={{ opacity: 1 }}
    //       // exit={{ opacity: 0 }}
    //       // variants={overlayVariants}
    //       // className="modal-overlay"
    //       // layoutId={id}


    //       initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
    //       animate={{ opacity: 1, backdropFilter: "blur(5px)" }} 
    //       exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
    //       variants={overlayVariants}
    //       // className="modal-overlay"
    //       layoutId={id}
    //     >
    //       <motion.div
    //         // className="modal"
    //         // initial={{ y: "100vh" }}
    //         // animate={{ y: 0 }}
    //         // exit={{ y: "100vh" }}
    //         transition={{ duration: 0.5 }}
    //       >
    //         {children}
    //       </motion.div>
    //     </motion.div>
    //   )}
    // </AnimatePresence>
    <AnimatePresence mode="exit" initial={false} >
      {id && (
        <motion.div
       
          style={{ width: "100%" }}
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // exit={{ opacity: 0 }}
          // className={Styles.modalOverlay}
          // layoutId={id}
          // initial={{ opacity:0, }}
          animate={{
            opacity: 1,
            background: "#00000033", backdropFilter: "blur(1px)",

          }}
          // exit={{ opacity:0, }}
          className={Styles.modalOverlay}
          layoutId={id}
        // initial={{ opacity: 0, }}

        // transition={{ duration: 0.6 }}
        // variants={overlayVariants}
        >

          <motion.div

          // initial={{ opacity: 0,}}
          // animate={{ opacity: 1, }}
          // exit={{ opacity: 0, }}
          >
            {/* <div className={Styles.closeBtn}>
              <IconButton onClick={onToggleExpand} sx={{ border: "1px solid", }}>
                <CloseOutlinedIcon fontSize="small" />
              </IconButton>
            </div> */}
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>

  );
}
export default CAnimatePresence;
