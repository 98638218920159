import React from 'react'
import { Stack } from '@mui/material'
import MobileProfileSection from './mobileProfileSection'
import TopnavSearchComponent from '../../searchComponent/TopnavSearchComponent/TopnavSearchComponent'
import Styles from "./page.module.scss";
function MobileHeaderSection() {
    return (
        <div className={Styles.container}>
            <Stack mt={2} spacing={3}>
                <MobileProfileSection />
                <TopnavSearchComponent />
            </Stack>
            
        </div>
    )
}

export default MobileHeaderSection