import React, { useEffect, useState } from 'react'
import { useGetAllProductsQuery } from '../../../store/slice'
import { useParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import ProductCard from '../../shared/productCard/Index'
import SkeletonProduct from '../../atoms/Skeletons/SkeletonProduct'
import Styles from "./ShopCategoriesGrid.module.scss"
function ShopCategoriesGrid() {
    const { categoryName } = useParams()
    console.log("categoryName", categoryName);
    const { data: getAllProducts, isSuccess: apiCallSuccess } = useGetAllProductsQuery()
    // const [products, setProducts] = useState([])

    const abc = getAllProducts?.filter((item) => item.item_category?.toLowerCase()?.includes(categoryName))
    // console.log("abc", abc);
    // useEffect(() => {
    //     // let a = getAllProducts?.filter((item) => item.shop_category?.toLowerCase() === categoryName)
    //     let a = getAllProducts?.filter((item) => item.item_category?.toLowerCase() === categoryName)?.slice(0, 40)
    //     setProducts(a)
    // }, [categoryName, getAllProducts])

    const products = getAllProducts?.filter((item) => item.item_category?.toLowerCase()?.includes(categoryName))
    console.log("products1", products);
    return (
        <div className={Styles.container}>
            <Box my={4}>
                <Grid container columnSpacing={2} rowSpacing={3}>

                    {
                        apiCallSuccess ?
                            products?.slice(0, 40)?.map((item, index) => {
                                return (
                                    <Grid key={index} item xs={6} sm={4} md={3} lg={3} >
                                        <ProductCard props={item} />
                                    </Grid>
                                )
                            })
                            :
                            (
                                new Array(10).fill(0).map((_, index) => (
                                    <Grid key={index} item xs={6} sm={4} md={3} lg={3}>
                                        <SkeletonProduct />
                                    </Grid>
                                )

                                )
                            )

                    }

                </Grid>
            </Box>
        </div>
    )
}

export default ShopCategoriesGrid