import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setupcomingPath } from '../store/reducers/Index';

export default function useAuthNavigate() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginCheck = useSelector((state) => state.products.login);


    const authNavigate = (url) => {
        console.log("url", url);
        if ((url === "/orders" || url === "/profile") && !loginCheck) {
            navigate("/signin")
            dispatch(setupcomingPath(url))
        } else {
            navigate(url)
        }

        // return navigate(url)
    }

    return authNavigate;
}
