import React from 'react'
import { wholphinLogo } from '../../assets/icons'
import Styles from "./wholphinLogo.module.scss"
function WholphinLogo() {
    return (
        <div
            className={Styles.logoImg}
            style={{ backgroundImage: `url(${wholphinLogo})` }}
        />
    )
}

export default WholphinLogo