import React from 'react';
import Styles from "./InfoCard.module.scss";
export default function InfoCard(props) {
    const { children } = props
    return (
        <div
            className={Styles.infoCardContainer}
        >{children}</div>
    )
}
