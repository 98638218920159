
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Skeleton, Stack, styled } from '@mui/material';
import Styles from "./headerTabsComponent.module.scss";
import SliderComponent from './Slider';
import { categoryListItems } from '../pages/home/data'; 
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div hidden={value !== index}     {...other}   >
            {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
        </div>
    );
}
const CTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    ".MuiTabs-scroller": {
        overflow: "auto !important",
        scrollbarWidth: " none",
    }
});

const CTab = styled(Tab)({
    fontSize: ".875rem",
    fontWeight: 700,
    lineHeight: 1.4285714286,
    padding: 0,
    margin: 0,
    marginRight: "1.5rem",
    textAlign: "initial",
    textTransform: "none",
    '&.Mui-selected': {
        color: 'black',
    },
})

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const categoryItems = [
    {
        id: 1,
        title: "Furniture",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/furniture-fu001.jpeg?imwidth=160",
    },
    {
        id: 2,
        title: "Beds & mattresses",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/beds-mattresses-bm001.jpeg?imwidth=160",
    },
    {
        id: 3,
        title: "Home textiles",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/textiles-tl001.jpeg?imwidth=160",
    },
    {
        id: 4,
        title: "Decoration",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/decoration-de001.jpeg?imwidth=160",
    },
    {
        id: 5,
        title: "Lighting",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/lighting-li001.jpeg?imwidth=160",
    },
    {
        id: 6,
        title: "Kitchenware & tableware",
        img: "https://www.ikea.com/in/en/range-categorisation/images/product/kitchenware-tableware-kt001.jpeg?imwidth=160",
    },
    {
        id: 7,
        title: "Baby & children",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/baby-children-bc001.jpeg?imwidth=160",
    },
    {
        id: 8,
        title: "Food & beverages",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/food-beverages-fb001.jpeg?imwidth=160",
    },
    {
        id: 9,
        title: "Smart home",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/smart-home-hs001.jpeg?imwidth=160",
    },
    {
        id: 8,
        title: "Food & beverages",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/food-beverages-fb001.jpeg?imwidth=160",
    },
    {
        id: 9,
        title: "Smart home",
        img: "https://www.ikea.com/global/assets/range-categorisation/images/product/smart-home-hs001.jpeg?imwidth=160",
    },


]
const categoryItemsByHouse = [
    {
        id: 1,
        title: "Bedroom",
        img: "https://www.ikea.com/images/a-calm-bedroom-with-a-large-bed-a-wooden-desk-a-dynamic-grey-97b26da5f0394ee77a37dac6b13f22f7.jpg?imwidth=300",
    },
    {
        id: 2,
        title: "Living room",
        img: "https://www.ikea.com/images/a-calm-bedroom-with-a-large-bed-a-wooden-desk-a-dynamic-grey-97b26da5f0394ee77a37dac6b13f22f7.jpg?imwidth=300",
    },
    {
        id: 3,
        title: "Children's room",
        img: "https://www.ikea.com/images/a-bedroom-with-a-kura-reversible-bed-and-another-bed-paralle-2e66ab0bad65e50092be14cf43bc687b.jpg?imwidth=300",
    },
    {
        id: 4,
        title: "Home office",
        img: "https://www.ikea.com/images/a-colourful-room-with-a-workspace-centred-around-a-white-tro-2623e9c04d7e384b20c6f291ca946779.jpg?imwidth=300",
    },
    {
        id: 5,
        title: "",
        img: "",
    },
    {
        id: 6,
        title: "",
        img: "",
    },
]



export default function HeaderTabsComponent() {
    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <section className={Styles.container}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CTabs value={value} onChange={handleChange}  >
                    <CTab label={<>Shop Products</>} {...a11yProps(0)} />
                    <CTab label={<>Shop by rooms</>} {...a11yProps(1)} />
                    <CTab label={<div>Offers</div>} {...a11yProps(2)} />
                    <CTab label={<div>New at wholphin</div>} {...a11yProps(3)} />
                    <CTab label={<div>Shop Products</div>} {...a11yProps(4)} />
                    <CTab label={<div>Shop Products</div>} {...a11yProps(5)} />
                    <CTab label={<div>Shop Products</div>} {...a11yProps(6)} />
                </CTabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <CategoryList items={categoryListItems}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <CategoryList items={categoryItemsByHouse} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <CategoryList items={categoryItems} />
            </CustomTabPanel>
        </section>
    );
}




const CategoryList = (props) => {
    const { items } = props
    return (
        <div>
            <div className={Styles.categoryContainer}>
                <SliderComponent data={items} />
            </div>
        </div>
    )
}


const LoaderComponent = () => {
    return (
        <Stack flexDirection={"row"} gap={2} justifyContent={"space-between"}>
            {
                [...Array(5)].map((_, index) => {
                    return (
                        <Stack alignItems={"center"} key={index}>
                            <Skeleton variant="circular" width={80} height={80} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={90} />
                        </Stack>
                    )
                })
            }
        </Stack>
    )
}