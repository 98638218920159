
import { IconButton, styled } from "@mui/material";

const CustomeIconBtn = styled(IconButton)({
    "&.Mui-disabled": {
      pointerEvents:"auto",
      cursor: "not-allowed",
    },

});

export default function IconBtn(props) {
  return <CustomeIconBtn {...props}>{props.children}</CustomeIconBtn>;
}
