import React from 'react'
import Styles from "./ImageThumbnail.module.scss";
export default function ImageThumbnail(props) {
    const { src } = props
    return (
        <div
            style={{ backgroundImage: `url(${src})` }}
            className={Styles.image}
        />
    )
}
