import React from 'react'
import Styles from "./ProductColorSizeQty.module.scss";
import Text from '../text/Text';
function ProductColorSizeQty(props) {
    const { item } = props
    return (
        <div className={Styles.container}>
            <div className={Styles.colorBox} style={{ backgroundColor: "#7A5548" }} />
            <Text className={Styles.text}>Color</Text>
            <div className={Styles.v_line} />
            <Text className={Styles.text}>Size = 40</Text>
            <div className={Styles.v_line} />
            <Text className={Styles.text}>Qty = {item?.item_quantity}</Text>
        </div>
    )
}

export default ProductColorSizeQty