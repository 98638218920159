import React from 'react'
import IconBtn from '../../atoms/IconBtn/IconBtn'
import { Add, Remove } from '@mui/icons-material'
import Styles from "./CounterButton.module.scss";
import { useDispatch } from 'react-redux';
import { addToCart, decrementItem, incrementItem } from '../../../store/reducers/Index';

export default function CounterButton(props) {
    const { item } = props
    const dispatch = useDispatch();
    const increment = () => {
        if (item.count < 1) {
            dispatch(addToCart(item));
        } else {
            dispatch(incrementItem(item));
        }
    };

    const decrement = () => {
        if (item.count > 1) {
            dispatch(decrementItem(item));
        }
    };


    return (
        <div className={Styles.counter}  >
            <IconBtn onClick={decrement} disabled={item.count <= 1 && true}>
                <Remove sx={{ color: "black", fontSize: { xs: "0.8rem", sm: "1.15rem" } }} />
            </IconBtn>
            <span className={Styles.counterText}>{item.count}</span>
            <IconBtn onClick={increment}>
                <Add sx={{
                    color: "black", fontSize: { xs: "0.8rem", sm: "1.15rem" }
                }}

                />
            </IconBtn>
        </div>
    )
}
