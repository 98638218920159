import React from "react";
import { TopNavComponent } from "../../components/layout/header/Index";

import Profile from "./page";
import { FooterCompoment } from "../../components/layout/footer/Index";



export default function Index() {
  return (
    <React.Fragment>
      <div className="animate pb-md-0 padding2">
        <TopNavComponent />

        {/* <HeaderComponent />

        <ListComponent /> */}

        <Profile />
        <FooterCompoment />
      </div>
    </React.Fragment>
  );
}
