import React from 'react'
import Styles from "./PageOffersSection.module.scss";
import Slider from 'react-slick';

import adidasLogo from "../../assets/logos/adidas-logo.png";
import pumaLogo from "../../assets/logos/puma-logo.png";
import redtapeLogo from "../../assets/logos/redtape-logo.png";
import { Stack } from '@mui/material';
import offeresImag from "../../assets/images/offeres-image1.png";
import offeresImag1 from "../../assets/images/offeres-image.png";
import offeresImag2 from "../../assets/images/luxuryFashion.png";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            // style={{ ...style, }}
            onClick={onClick}
        >
            <ArrowForwardIosOutlinedIcon sx={{ fontSize:16,width:"34px", color: "black" }} />
        </div>
    );
}
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            // style={{ ...style,   }}
            onClick={onClick}
        >
            <ArrowBackIosNewOutlinedIcon sx={{ fontSize:16,width:"34px", color: "black" }} />
        </div>
    );
}

var settings = {
    // className: "center",
    // infinite: true,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // swipeToSlide: true,
    // speed:1500,
    // initialSlide: 0,

    // dots: true,
    className: "center",
    // centerMode: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    // swipeToSlide: true,
    fade: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <PrevArrow />,
    // responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 3,
    //             slidesToScroll: 3,
    //             // infinite: true,
    //             // dots: true
    //         }
    //     },
    //     {
    //         breakpoint: 900,
    //         settings: {
    //             slidesToShow: 2,
    //             slidesToScroll: 2,
    //             initialSlide: 2
    //         }
    //     },
    //     {
    //         breakpoint:768,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1
    //         }
    //     }
    // ]
};

const offereData = [
    {
        discount: "Up to 30% off",
        title1: "On footwear",
        title2: "Get discount for every order, only valid for today",
        img: offeresImag,
        brand1: adidasLogo,
        brand2: pumaLogo,
        brand3: redtapeLogo,
    },
    {
        discount: "Up to 30% off",
        title1: "On footwear",
        title2: "Get discount for every order, only valid for today",
        img: offeresImag1,
        brand1: pumaLogo,
        brand2: redtapeLogo,
        brand3: adidasLogo,
    },
    {
        discount: "Up to 30% off",
        title1: "On footwear",
        title2: "Get discount for every order, only valid for today",
        img: offeresImag2,
        brand1: redtapeLogo,
        brand2: adidasLogo,
        brand3: pumaLogo,
    },
]

export default function PageOffersSection({ shopName }) {
    return (
        <section className="slider-container1">
            <Slider {...settings}
            >
                {offereData.map((item) => <OffersCard item={item} shopName={shopName} />)}
    
            </Slider>
        </section>

    )
}



const OffersCard = ({ shopName, item }) => {
    return (
        <section className={Styles.card1}>

            <section className={Styles.space_b}>
                <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                    {/* <div
                        className={Styles.brandLogo}
                        style={{ backgroundImage: `url(${pumaLogo})` }} />
                    <div
                        className={Styles.brandLogo}
                        style={{ backgroundImage: `url(${redtapeLogo})` }} />
                    <div
                        className={Styles.brandLogo}
                        style={{ backgroundImage: `url(${adidasLogo})` }} /> */}
                    <img src={item.brand1} className={Styles.brandLogo} />
                    <img src={item.brand2} className={Styles.brandLogo} />
                    <img src={item.brand3} className={Styles.brandLogo} />

                </Stack>

                <div className={Styles.shopName}>
                    {shopName}
                </div>
            </section>


            <section className={Styles.space_b} style={{height:"90%",}}>
                <div className={Styles.SpecialOfferArticle}>
                    <div className={Styles.SpecialOfferName}>{item.discount}</div>
                    {/* <div className={Styles.SpecialOfferText}>{item.title1}</div> */}
                    <div className={Styles.SpecialOfferDesc}>{item.title1}</div>
                    {/* <div className={Styles.SpecialOfferDesc}>{item.title2} </div> */}
                </div>

                <div
                    // style={{ backgroundImage: "url('/media/home/Image.svg')" }}
                    style={{ backgroundImage: `url(${item.img})` }}
                    className={Styles.SpecialOfferImg}
                />

            </section>
            
        </section>
    )
}