import { Button, styled } from '@mui/material'
import React from 'react';
import Styles from "./MButton.module.scss";
const CustomeButton = styled(Button)(({ theme, custome, variant = "primary", sq, size = "large", height, fweight }) => {
    const getColorStyles = () => {
        if (variant === 'primary') {
            return {
                color: "#FFFFFF",
                background: '#101010',
                // boxShadow: "4px 8px 24px 0px #10101040",
                '&:hover': {
                    background: "#101010",
                    opacity: "0.9"
                },
            };
        }
        if (variant === 'secondary') {
            return {
                color: " #35383F",
                background: "#E7E7E7",
                '&:hover': {
                    background: "#E7E7E7",
                },
            };
        }
        if (variant === 'outline') {
            return {
                color: "#101010",
                border: "2px solid #101010",
            };
        }
    }

    return {
        ...getColorStyles(),
        borderRadius: sq ? "12px" : "50px",
        textTransform: "none",
        height: height ? height : "58px",
        minWidth:"fit-content",
        padding: size == 'small' ? '6px 16px' : size === 'large' ? '18px 16px' : '10px 10px',
        fontSize: "16px",
        fontWeight: fweight ? fweight : 700,
        textWrap: "nowrap",
        fontFamily:"Urbanist",
        // ':hover': {
        //     background: "none",
        // },
        '@media (max-width: 420px)': {
            padding: "10px",
            fontSize: "14px",
        },
    }
})

function MButton(props) {
    return (
        <CustomeButton  {...props} className={Styles.container}>
            {/* <div className={Styles.text}> */}
                {props.children}
            {/* </div> */}
        </CustomeButton>
    )
}

export default MButton