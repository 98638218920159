import React from "react";
import ReactDOM from "react-dom/client"; 
import { HashRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./index.scss";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store/Index";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>

      <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <App />
         
        </HashRouter>
        </PersistGate>
      </ReduxProvider>
 
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
