
import { AiFillHome } from "react-icons/ai";
import { BsCart, BsBag } from "react-icons/bs";
import { CiWallet, CiUser } from "react-icons/ci";

import { BiHome } from "react-icons/bi";
import { FcAbout } from "react-icons/fc";

import { appliancesIcon, electronicsIcon, fashionIcon, foodIcon, giftIcon } from "../../../assets/icons";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export const navs = [
  {
    name: "Home", icon: <BiHome
    // size={18} 
    />, url: "/"
  },
  {
    name: "Cart", icon: <BsBag
    // size={15}
    />, url: "/cart"
  },
  {
    name: "Orders", icon: <BsCart
    // size={16}
    />, url: "/orders"
  },
  // {
  //   name: "Wallet", icon: <CiWallet
  //   // size={18}
  //   />, url: "/payment-method"
  // },
  {
    name: "Profile", icon: <CiUser
    // size={18}
    />, url: "/profile"
  },
  {
    name: "About Us",
    // icon:<FcAbout  />,
    icon: <InfoOutlinedIcon fontSize="20px" />,
    url: "/aboutUs"
  }
];


export const categoryListItems = [
  { id: "", title: "Fashion", img: fashionIcon, url: "fashion" },
  { id: "", title: "Appliances", img: appliancesIcon, url: "appliances" },
  { id: "", title: "Food", img: foodIcon, url: "food" },
  { id: "", title: "Electronics", img: electronicsIcon, url: "electronics" },
  { id: "", title: "Accessories", img: "", url: "accessories" },
  { id: "", title: "Gifts", img: giftIcon, url: "gifts" },
];