import React from "react";
import { TopNavComponent } from "../../components/layout/header/Index";
import { SectionWise } from "./SectionWise";
import { Box, Stack } from "@mui/material";
import { FooterCompoment } from "../../components/layout/footer/Index";
import { Link } from "react-router-dom";
import Styles from "./AboutUs.module.scss"

export default function AboutUs() {
    return (
        <div className="padding2">
            <TopNavComponent />

            <Box sx={{ py: { xs: 3, sm: 2 } }}>
                <Stack spacing={3} paddingBottom={10}>
                    <SectionWise
                        title="About Us"
                        desc1={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                        desc2={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                    />
                    <SectionWise
                        title="Pricing"
                        desc1={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                        desc2={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                    />
                    <Link to={"/privacyPolice"} className={Styles.link}>Privacy Police</Link>
                    {/* <SectionWise
                        title={`Privacy Police`}
                    desc1={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                    desc2={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                    /> */}
                    <SectionWise
                        title="Terms and Conditions"
                        desc1={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                        desc2={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                    />
                    <SectionWise
                        title="Cancellation/Refund Policy"
                        desc1={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                        desc2={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                    />
                    <SectionWise
                        title="Contact Us"
                        desc1={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                        desc2={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vulputate felis. Suspendisse potenti. Nullam quis mauris dui. Nunc nibh sapien, hendrerit nec venenatis in, pretium in enim. Proin scelerisque dictum felis vel varius. Nulla ultricies varius urna, ac rhoncus justo cursus venenatis. Donec at quam lectus. Vestibulum finibus, ante a mollis ultrices, nibh est luctus ligula, a dictum diam odio eu metus. Nam massa ipsum, ornare vel augue vel, porttitor tincidunt neque. Vivamus vel suscipit nunc, eu suscipit mi.`}
                    />
                </Stack>
            </Box>

            <FooterCompoment />
        </div>
    );
}
