import React from 'react'
import { FaRegHeart } from "react-icons/fa";
import Styles from "./WishlistIcon.module.scss"
export default function WishlistIcon() {
    return (
        <div className={Styles.heartIcon}>
            <FaRegHeart />
        </div>
    )
}
