import React from 'react'
import ImageThumbnail from '../atoms/thumbnails/ImageThumbnail/ImageThumbnail'
import { notFoundImg } from '../../assets/icons'
import Styles from "./EmptyCart.module.scss"
import Text from '../atoms/text/Text'
import { Stack } from '@mui/material'
import MButton from '../atoms/button/MButton'
function EmptyCart({ title, desc, onClick, btnText }) {
    return (
        <div className={Styles.container}>
            <div className={Styles.image}>
                <ImageThumbnail src={notFoundImg} />
            </div>

            <Stack gap="12px" alignItems={"center"} width={"100%"}>
                <Text className={Styles.title}>{title}</Text>
                <Text className={Styles.desc}>{desc}</Text>
                {
                    btnText ?
                        <div>
                            <MButton height="auto" size="small" onClick={onClick}>{btnText}</MButton>
                        </div>
                        : <></>
                }

            </Stack>


        </div>
    )
}

export default EmptyCart