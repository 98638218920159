import React from 'react'
import Styles from "./OrdersItemCard.module.scss";
import { Stack } from '@mui/material';
import { CardPrice, CardTitle } from '../../atoms/text/CardTitle';
import ImageThumbnail from '../../atoms/thumbnails/ImageThumbnail/ImageThumbnail';
import ProductColorSizeQty from '../../atoms/ProductColorSizeQty/ProductColorSizeQty';

function OrdersItemCard({ item }) {
    return (
        <div className={Styles.productCard}>
            <div className={Styles.imageContainer}>
                <ImageThumbnail src="https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg" />
            </div>

            <Stack justifyContent={"space-between"} overflow={"hidden"}>
                <CardTitle>{item?.item_name}</CardTitle>
                <ProductColorSizeQty item={item}/>
                <CardPrice>${item?.item_price}</CardPrice>
            </Stack>
        </div>
    )
}

export default OrdersItemCard