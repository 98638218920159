import "./styles/theme.scss";
import { Routes, Route, } from "react-router-dom";
import Home from "./pages/home/Index";
import React from "react";
import ProductDetail from "./pages/productDetail/Index";
import Cart from "./pages/cart/Index";
import Profile from "./pages/profile/Index";
import Orders from "./pages/orders/Index";

import LoginScreen from "./pages/login/page";
import AllProductsPage from "./pages/AllProducts/AllProductsPage";
import ShopCategoryPage from "./pages/shopCategory/ShopCategoryPage";
import ScrollToTop from "./components/ScrollToTop";
import AuthRoute from "./components/Auth/Auth";
import ProductsByShopId from "./pages/ProductsByShopId/ProductsByShopId";
import AboutUs from "./pages/aboutUs/AboutUs";
import { CheckoutProccessing } from "./pages/CheckoutProccessing/CheckoutProccessing";
import PrivacyPolice from "./pages/PrivacyPolice/PrivacyPolice";
import { TopNavComponent } from "./components/layout/header/Index";

export function App() {

  return (
    <div >
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/:productId" element={<ProductDetail />} />
        <Route path="/all-products" element={<AllProductsPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkoutProceesing" element={<CheckoutProccessing />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/privacyPolice" element={<PrivacyPolice />} />

        <Route path="/signin" element={<LoginScreen />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/category/:categoryName" element={<ShopCategoryPage />} />
        <Route path="/shop/:shopId" element={<ProductsByShopId />} />
      </Routes>
    </div>
  );
}
