import React from 'react'
import Styles from "./Styles.module.scss";

export  function CardTitle(props) {
    const { children } = props
    return (
        <div className={Styles.cardTitle}>{children}</div>
    )
}

export const CardPrice = (props) => {
    const { children } = props;
    return (
        <div className={Styles.cardPrice}>{children}</div>
    )

}