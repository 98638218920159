import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import MButton from "../../atoms/button/MButton";

export default function OrderSuccess({ props }) {
  const navigate = useNavigate();
  const { confirm, setConfirm } = props;

  const ref = useRef(null);

  useEffect(() => {
    const leave = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setConfirm(false);
        // navigate("/");
      }
    };
    document.addEventListener("mousedown", leave);
  }, [ref]);

  return (
    <div className={`${styles.box} ${confirm ? styles.show : ""}`}>
      <div ref={ref} className={styles.container}>
        <div
          className={styles.img}
          style={{ backgroundImage: "url('/media/orderSuccess/Group.svg')" }}
        ></div>
        <div className={styles.title}>Order Successful!</div>
        <div className={styles.text}>You have successfully made order</div>

        {/* <div> */}
          <MButton fullWidth onClick={()=>navigate("/orders")}>View Order</MButton>
          <MButton fullWidth variant="secondary">View E-Receipt</MButton>
        {/* </div> */}
      </div>
    </div>
  );
}
