import React from 'react'
import Styles from "./Styles.module.scss";

type propsData = {
    errorText: string
}
export default function ErrorText(props: propsData) {
    return (
        <div className={Styles.errorText}>
            {props.errorText}
        </div>
    )
}
