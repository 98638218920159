import React, { useRef, useState } from 'react'
import { Autocomplete, } from '@react-google-maps/api'
import Styles from "./GSearchInput.module.scss";

function GSearchInput(props) {
    const { setCoords } = props
    const autocompleteRef = useRef(null);
    const [searchResult, setSearchResult] = useState("")

    // const { isLoaded, loadError } = useJsApiLoader({
    //     googleMapsApiKey: "AIzaSyB2gIWgH0pXIdx8NWNi9IfOMQ_2rSvzGMs",
    //     version: "weekly",
    //     libraries: ["places"],
    // })
    const onLoad = (autocomplete) => {
        autocompleteRef.current = autocomplete;
        console.log("Autocomplete loaded:", autocomplete);
    };

    const onPlaceChanged = () => {
        console.log("trigger");
        if (autocompleteRef.current) {
            const places = autocompleteRef.current.getPlace();
            console.log("Place selected:", places);
            // let lat = places[0].geometry.location.lat()
            let lat = places.geometry.location.lat()
            console.log("lat", lat);
            setCoords((prev) => ({
                ...prev,
                lat: places.geometry.location.lat(),
                lng: places.geometry.location.lng()
            }))
            // Handle selected place details here
        }
    };
    // const onInput = (e) => {
    //     console.log("User input:", e.target.value);
    // };
  
    return (
        <>

            <Autocomplete
                onLoad={onLoad}
                // onPlacesChanged={onPlaceChanged}
                onPlaceChanged={onPlaceChanged}
                ref={autocompleteRef}
            // style={{ zIndex: 1002 }}
            >
                <input
                    className={Styles.input}
                    placeholder="Search a place"
                    // onInput={onInput}
                />
            </Autocomplete>

        
        </>
    )
}

export default GSearchInput