import React from 'react';
import Styles from "./backButton.module.scss";
function BackButton(props) {
    const { onClick } = props
    return (
        <>
            <div
                style={{ backgroundImage: "url('/icons/Arrow - Left.svg')" }}
                onClick={onClick}
                className={Styles.backIcon}
            />
        </>
    )
}

export default BackButton