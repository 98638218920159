import React, { useState } from 'react'
import Styles from "./Checkout.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Stack } from '@mui/material';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import CustomeModal from '../../atoms/Modal/CustomeModal';
import AddNewAddress1 from '../AddressCard/AddNewAddress1';
import { useLocation, useNavigate } from 'react-router-dom';

import Text from '../../atoms/text/Text';
import { setupcomingPath } from '../../../store/reducers/Index';
export default function CheckoutComponent() {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();

    const cart = useSelector((state) => state.products.cart);
    const userData = useSelector((state) => state.products.userData)

    const [openAddress, setOpenAddress] = useState(false)
    const vals = cart.map((idx) => idx.item_price * idx.count);
    const count = cart.map((idx) => idx.count);
    const totalCount = count.reduce((prev, curr) => prev + curr, 0);
    let totalPrice = 0;
    for (let i = 0; i < vals.length; i++) {
        totalPrice = totalPrice + Number(vals[i])
    }

    const checkOut = () => {

        if (location.pathname === "/cart") {
            if (userData?.id) {
                if (userData?.address?.trim() !== "" && userData?.address !== null && userData?.address !== undefined) {
                    navigate("/checkoutProceesing")
                } else {
                    alert("please Enter Delivery Address")
                }

            } else {
                navigate("/signin")
                dispatch(setupcomingPath("/checkoutProceesing"))
            }
        } else {
            navigate("/cart")
        }

    }

    return (
        < >
            {
                cart.length > 0 &&
                <div className={Styles.mainContainer}>
                    <div className={Styles.mainContainer1}>
                        {
                            userData?.address ?
                                <div className={Styles.firstSection} onClick={() => setOpenAddress(true)}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={0.5}
                                        overflow="hidden">
                                        <PlaceOutlinedIcon fontSize='small' />
                                        <Text className={Styles.text11}>{userData?.address}</Text>
                                    </Stack>
                                    <Stack direction="row"
                                        alignItems="center"
                                        gap={1}>
                                        <Text className={Styles.text12}>
                                            Change
                                            <EditOutlinedIcon sx={{ ml: 1 }} fontSize={"14px"} />
                                        </Text>
                                    </Stack>
                                </div>
                                :
                                <div className={Styles.firstSection} onClick={() => setOpenAddress(true)}>
                                    <Stack direction="row" alignItems="center" gap={1} width="100%" overflow="hidden">
                                        <AddLocationIcon fontSize='small' />
                                        <span>Add Delivery Address</span>
                                    </Stack>
                                    {/* <div>Add </div> */}
                                </div>
                        }
                        <div className={Styles.secondSection1}
                            onClick={checkOut}
                        >
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                gap={"4px"}
                                justifyContent={"center"}
                            >
                                <div className={Styles.text22}>{totalCount} items</div>
                                <div className={Styles.v_line} />

                                <div className={Styles.text22}>
                                    <CurrencyRupeeOutlinedIcon sx={{ fontSize: "14px", color: "white" }} />
                                    {totalPrice} </div>
                            </Stack>

                            <div className={Styles.btnText} >
                                Checkout
                            </div>

                        </div>



                    </div>
                </div>
            }

            {
                // openAddress &&
                <CustomeModal title="Add Address" outSide={false} setShow={setOpenAddress} show={openAddress} >
                    <AddNewAddress1 close={() => setOpenAddress(false)} profileData={userData} />
                </CustomeModal>
            }

        </>

    )
}
