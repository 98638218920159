import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

export default function PopoverComp({ children, label }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        // variant="contained"
        onClick={handleClick}
        endIcon={anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        sx={{
          backgroundColor: "#eeeeee",
          color: "black",
          borderRadius: "18px",
          textTransform: "capitalize",
          padding: "0.5rem 1.5rem",
          fontWeight: "600",
          fontSize: "0.8rem",
        }}
      >
        {label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        {children}
      </Popover>
    </div>
  );
}
