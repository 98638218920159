import { createSlice } from "@reduxjs/toolkit";

export const productsSlice = createSlice({
  name: "products",
  initialState: {

    login: false,
    upcomingPath: "/",
    userData: {},
    userAuth: { email: "", password: "" },
    itemId: "1",
    category: "",
    singleProduct: {},
    products: [],
    cart: [],
    orders: {
      ongoing: [],
      completed: [],
    },
    trackOrder: {},
    shippingType: {},
    promo: {},
    paymentMethod: {},
    userAddress: {},
  },

  reducers: {
    setLoginStatus: (state, action) => {
      state.login = action.payload;
    },
    setupcomingPath: (state, action) => {
      state.upcomingPath = action.payload
    },
    setUserProfileData: (state, action) => {
      state.userData = action.payload
    },
    signup: (state, action) => {
      state.userAuth = action.payload;
    },
    productList: (state, action) => {
      state.products = action.payload;
      // console.log("producrs added", state.products);
    },
    productDetail: (state, action) => {
      state.singleProduct = action.payload;

    },
    productId: (state, action) => {
      state.itemId = action.payload;
    },
    getCategoryName: (state, action) => {
      state.category = action.payload;
    },
    // addToCart: (state, action) => {
    //   const item = state.products.find((idx) => idx.sno === action.payload.sno);
    //   // item.inCart = true;
    //   item.count = 1;
    //   state.singleProduct = item;
    //   state.cart.push(item);
    // },

    addToCart: (state, action) => {
      console.log("Current state:", JSON.parse(JSON.stringify(state)));
      const itemInCart = state.cart.find((item) => item.sno === action.payload.sno);
      if (itemInCart) {
        itemInCart.count += 1;
      } else {
        const item = {
          count: 1,
          ...action.payload
        };
        state.cart.push(item);
      }
    },
    emptyCartItems: (state) => {
      state.cart = []
    },

    deleteItem: (state, action) => {
      // const item = state.products.find((idx) => idx.sno === action.payload.sno);
      // item.inCart = false;
      // item.count = 0;
      // state.singleProduct = item;
      const items = state.cart.filter((idx) => idx.sno !== action.payload.sno);
      state.cart = items;
    },
    // incrementItem: (state, action) => {
    //   const item = state.products.find((idx) => idx.sno === action.payload.sno);
    //   item.count += 1;
    //   state.singleProduct = item;
    //   const items = state.cart.find((idx) => idx.sno === item.sno);
    //   items.count = item.count;
    // },
    // decrementItem: (state, action) => {
    //   const item = state.products.find((idx) => idx.sno === action.payload.sno);
    //   item.count -= 1;
    //   state.singleProduct = item;
    //   const items = state.cart.find((idx) => idx.sno === item.sno);
    //   items.count = item.count;
    // },
    incrementItem: (state, action) => {
      const item = state.cart.find((idx) => idx.sno === action.payload.sno);
      item.count += 1;
      // state.singleProduct = item;
      // const items = state.cart.find((idx) => idx.sno === item.sno);
      // items.count = item.count;
    },
    decrementItem: (state, action) => {
      const item = state.cart.find((idx) => idx.sno === action.payload.sno);
      item.count -= 1;
      // state.singleProduct = item;
      // const items = state.cart.find((idx) => idx.sno === item.sno);
      // items.count = item.count;
    },
    addShippingType: (state, action) => {
      state.shippingType = action.payload;
    },
    addPromo: (state, action) => {
      state.promo = action.payload;
    },
    addAddress: (state, action) => {
      state.userAddress = action.payload;
    },
    addPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    getOrders: (state, action) => {
      state.orders.ongoing = action.payload;
      const item = state.products.find((idx) => idx.inCart === true);
      item.inCart = false;
      item.count = 0;
      state.cart = [];
    },
    trackOrder: (state, action) => {
      state.trackOrder = action.payload;
    },
  },
});

export const {
  setLoginStatus,
  setupcomingPath,
  setUserProfileData,
  signup,
  productId,
  getCategoryName,
  productDetail,
  productList,
  addToCart,
  deleteItem,
  incrementItem,
  decrementItem,
  emptyCartItems,
  addShippingType,
  addPromo,
  addAddress,
  addPaymentMethod,
  getOrders,
  trackOrder,
} = productsSlice.actions;

export default productsSlice.reducer;
