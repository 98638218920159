import { Modal } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import styles from "./DeleteCartItem.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import CartCard from '../../pages/cart/cart/cartCard';
import { deleteItem as removeItem } from '../../../store/reducers/Index';
export const DeleteCartItem = (props) => {
  const { deleteItem, setDeleteItem } = props
  const ref = useRef(null);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.cart);

  useEffect(() => {
    // const leave = (event) => {
    //   if (ref.current && !ref.current.contains(event.target)) {
    //     setDeleteItem({ ...deleteItem, state: false });
    //   }
    // };
    // document.addEventListener("mousedown", leave);
  }, [ref]);

  return (
    <div
      className={`${styles.DeleteProductComponent} ${deleteItem.state && deleteItem.data.sno ? styles.show : ""
        }`}
    >
      <div
        ref={ref}
        className={`${styles.deleteItem} ${deleteItem.state ? styles.show : ""
          }`}
      >
        <div className={styles.smallBar}></div>
        <div className={styles.reviewTitle}>Remove From Cart?</div>
        <div className={styles.longBar}></div>
        <div className="mx-auto">
          {products.map((props, index) => {
            if (props.sno === deleteItem.data.sno) {
              return (
                <CartCard
                  key={props.sno}
                  item={props}
                  close={() => setDeleteItem({ state: false, data: {} })}
                  hideDeleteIcon={true}
                />
              );
            }
          })}
        </div>

        <div className={styles.longBar}>
          <div className="d-none"></div>
        </div>

        <div className={styles.btns}>
          <div
            onClick={() => setDeleteItem({ state: false, data: {} })}
            className=""
          >
            cancel
          </div>
          <div
            onClick={() => {
              dispatch(removeItem(deleteItem.data));
              setDeleteItem({ state: false, data: {} });
            }}
            className=""
          >
            Yes, Remove
          </div>
        </div>
      </div>
    </div>
  );
};