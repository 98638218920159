import React from 'react'
import { Stack, } from "@mui/material";
import { useDispatch } from 'react-redux';
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IconBtn from "../../../atoms/IconBtn/IconBtn";
import Styles from "./cartCard.module.scss";
import Text from "../../../atoms/text/Text";
import textStyles from "../TextStyles.module.scss";
import CounterButton from '../../../molecules/counterButton/counterButton';
import { CardTitle } from '../../../atoms/text/CardTitle';
import CartImage from '../../../atoms/thumbnails/CartImage';

function CartCard(props) {
    const { item, openDelete, hideDeleteIcon } = props
    const ProductImg = item.item_image.split(",")
    const dispatch = useDispatch();

   
    const removeItem = () => {
        // dispatch(deleteItem(item));
        openDelete()
    }

    return (
        <div className={Styles.cartcardContainer}>
            <Stack direction="row" spacing={{ xs:"16px", sm: 3.5 }}>
                <Stack spacing={2} alignItems="center">

                    <CartImage imageUrl={ProductImg[0]} />
                    <span className={`${textStyles.bldSm} ${Styles.greyBox}`}>
                        704.732.51
                    </span>
                </Stack>
                
                <Stack spacing={3} width="100%" overflow={"hidden"}>
                    <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between">
                        <Stack spacing={0.5} maxWidth={{md:"80%"}}>
                            <CardTitle>{item.item_name}</CardTitle>
                            <Text className={`${textStyles.txt} ${Styles.desc}`}>{item.item_short_description}</Text>
                        </Stack>
                        <div>
                            <CardTitle>Rs. {item.item_price}</CardTitle>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <Stack sx={{ display: { xs: "none", sm: "block" } }}>
                            <a href="" className={textStyles.txtSm}>
                                Check delivery and collect options
                            </a>
                        </Stack>
                        <Stack direction="row" spacing={{ xs: 1, sm: 2.5 }} alignItems="center">
                            <CounterButton item={item} />
                            <IconBtn>
                                <FavoriteBorderOutlinedIcon sx={{ color: "black", fontSize: { xs: "1.1rem", sm: "1.5rem" }, }} />
                            </IconBtn>
                            {
                                !hideDeleteIcon &&
                                <IconBtn>
                                    <DeleteOutlineOutlinedIcon
                                        onClick={removeItem}
                                        sx={{ color: "black", fontSize: { xs: "1.1rem", sm: "1.5rem" }, }} />
                                </IconBtn>
                            }

                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

        </div>
    );
}

export default CartCard