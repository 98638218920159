import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useGetAllProductsQuery } from "../../store/slice";
import Text from "../atoms/text/Text";
import Styles from "./prodcutsOffersSection.module.scss";

export function ProdcutsOffersSection(props) {
  const { list } = props;
  const { data: ProductsListData } = useGetAllProductsQuery({});
  const navigation = useNavigate();

  const imgFilterList = ProductsListData?.filter(
    (item) => item?.item_image
  ).slice(0, 20);

  const offersProducts = list ? list : imgFilterList;

  const imageUrl =
    "https://wholphintech.com/werp/main/server/products/uploaded_item_images/";

  return (
    <section className={Styles.sectionContainer}>
      
      <div className={Styles.leftSideContainer}>
        <Text className={Styles.validityContainer}>Ends tonight</Text>
        <div className={Styles.s_center}>
          <Text className={Styles.txtMd}>Up to</Text>
          <Text className={Styles.bldXl}>50% OFF</Text>
        </div>
      </div>

      <div className={Styles.rightSideContainer}>
        {offersProducts?.map((item) => {
          return (
            <div onClick={() => navigation(`/products/${item.sno}`)}>
              <motion.div className={Styles.productCard} whileTap={{ scale: 0.1 }}>
                <div
                  className={Styles.producImage}
                  style={{
                    backgroundImage: `url(${
                      imageUrl + item?.item_image.split(",")[0]
                    })`,
                  }}
                />
                <Text className={Styles.productPercentage}>15%</Text>
              </motion.div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
