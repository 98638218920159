import React, { useState } from "react";

import { FooterCompoment } from "../../components/layout/footer/Index";
import { TopNavComponent } from "../../components/layout/header/Index";
import { useSelector } from "react-redux";
import { DeleteCartItem } from "../../components/molecules/DeleteCartItem/DeleteCartItem";
import OrderSuccess from "../../components/shared/orderSuccess/Index";
import DesktopCart from "../../components/pages/cart/cart/page";
import EmptyCart from "../../components/EmptyCart/EmptyCart";
import { Grid } from "@mui/material";
import PageHeaderComponent from "../../components/shared/PageHeaderComponent/PageHeaderComponent";
import { useNavigate } from "react-router-dom";
import CustomeModal from "../../components/atoms/Modal/CustomeModal";

export default function Index() {
  const navigate=useNavigate()
  const [deleteItem, setDeleteItem] = useState({ state: false, data: {} });

  const cart = useSelector((state) => state.products.cart);
  const userData = useSelector((state) => state.products.userData)

const [openModal,SetModalOpen]=useState(false);

  const props = { deleteItem, setDeleteItem, cart, userData };

  return (
    <div className="padding2">
      <TopNavComponent />

      <PageHeaderComponent title="My Cart" search={true} />

      {cart?.length > 0 ? <DesktopCart {...props} /> :
        <>
          <EmptyCart
            title="Your Cart Is Currently Empty "
            desc="You don't have an cart item  at this time"
            btnText="Return To Shop"
            onClick={()=>navigate("/")}
          />
        </>
      }
      <CustomeModal show={deleteItem.state} setShow={()=>{setDeleteItem({ state: false, data: {} })}}>    <DeleteCartItem  {...props} />  </CustomeModal>

    
      <FooterCompoment />
    </div>
  );
}
