import EastIcon from "@mui/icons-material/East";
import Text from "../../atoms/text/Text";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { CardTitle } from "../../atoms/text/CardTitle";
import Styles from "./SquareCard.module.css";
import MainStyles from "../Categories.module.css"

export default function SquareCard({ data }) {
  const { name, subcategories } = data;
  const navigate = useNavigate()
  const onClick = (url) => {
    if (url) {
      navigate(`/category/${url}`)
    }
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Text className={MainStyles.cardTitle}>{name}</Text>
        <div className={Styles.iconBox}>
          <EastIcon sx={{ fontSize: "12px" }} />
        </div>
      </div>
      <div className={Styles.subCategoriesContainer}>
        {subcategories.map((cat) => (
          <div key={cat.name} className={Styles.subCategory} onClick={() => onClick(cat.url)}>
            <motion.div whileTap={{ scale: 0.1 }} className={MainStyles.imageBox}>
              <div
                style={{ backgroundImage: `url(${cat.image})` }}
                className={MainStyles.image}
              />
            </motion.div>
            <Text className={Styles.cardSubTitle}>{cat.name}</Text>
          </div>
        ))}
      </div>
    </div>
  );
}
