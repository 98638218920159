import { Box, Divider, Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Styles from "./productCard.module.scss";
import { motion, useInView } from 'framer-motion';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, emptyCartItems, productDetail } from '../../store/reducers/Index';
import HeroSection from '../molecules/heroSection/heroSection';
import MButton from '../atoms/button/MButton';
import CounterButton from '../molecules/counterButton/counterButton';
import { useParams } from 'react-router-dom';
import { useGetAllProductsQuery } from '../../store/slice';
import { useRef } from 'react';
import CustomeModal from '../atoms/Modal/CustomeModal';
import CancelProceedComponent from '../CancelProceedComponent/CancelProceedComponent';
import { FooterCompoment } from '../layout/footer/Index';


const sizes = [30, 32, 36, 40];
const colors = ["#444242", "#706e6e", "#864343", "#66b4f8", "#49896a"];

function ProductDetailsPage(props) {
    const { productId } = useParams();

    console.log("byId", props.byId);
    console.log("id", productId);

    const { data: productsList } = useGetAllProductsQuery()

    const dispatch = useDispatch();

    const productIdDetails = productId ? productsList?.find((item) => item?.sno === productId) : props.byId && productsList?.find((item) => item?.sno === props.byId)

    const cart = useSelector((state) => state.products.cart);
    const [selectedSize, setSelectedSize] = useState("")
    const [selectedColor, setSelectedColor] = useState("")
    const [showDeleteCart, setShowDeleteCart] = useState(false)

    const checkingCart = productId ? cart.find((item) => item?.sno === productId) : props.byId && cart.find((item) => item?.sno === props.byId)

    const imageUrl = "https://wholphintech.com/werp/main/server/products/uploaded_item_images/"
    const productImages = productIdDetails?.item_image ? productIdDetails?.item_image?.split(",").map((item) => ({
        imageUrl: imageUrl + item
    })) : []

    const addItemToCart = () => {
        if (cart.length > 0) {
            const shopId = cart.every((item) => item?.shop_id === productIdDetails?.shop_id)
            if (!shopId) {
                setShowDeleteCart(true)
                return;
            }
        }
        dispatch(addToCart(productIdDetails));
        setShowDeleteCart(false)
    };

    const deletePreviousCartItems = () => {
        dispatch(emptyCartItems())
        dispatch(addToCart(productIdDetails));
        setShowDeleteCart(false)
    }
    const ref = useRef(null)
    const isInView = useInView(ref)


    const selectSizeBtn = (size) => {
        setSelectedSize(size)
    }

    const selectColorBtn = (color) => {
        setSelectedColor(color)
    }
    return (
        <Box position={"relative"}>
            <motion.div
                className={Styles.detailsPageContainer}
                initial={{ opacity: 0, }}
                animate={{ opacity: 1, }}
                exit={{ opacity: 0 }}
                ref={ref}
            >

                <Grid container columnSpacing={6} height={"99%"} >
                    <Grid item xs={12} md={6}>
                        {/* <div style={{ position: "relative",  width:"100%" }}> */}
                        <section
                            ref={ref}
                            style={{
                                // paddingBottom: "30px",
                                transform: isInView ? "none" : "translateX(-250px)",
                                opacity: isInView ? 1 : 0,
                                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
                                position: "sticky",
                                top: productId ? "100px" : "5px",
                            }}>
                            <HeroSection images={productImages} />
                        </section>
                    </Grid>

                    <Grid item xs={12} md={6} ref={ref}>
                        <motion.section
                            ref={ref}
                            style={{
                                paddingBottom: "30px",
                                transform: isInView ? "none" : "translateX(250px)",
                                opacity: isInView ? 1 : 0,
                                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s"


                            }}

                        >
                            <div className={Styles.title}>{productIdDetails?.item_name}</div>
                            <div className={Styles.descriptionText}>{productIdDetails?.item_short_description}</div>

                            <div className={Styles.ratingBox}                    >
                                <span>{productIdDetails?.item_rating ? productIdDetails?.item_rating : "1.2k"} </span>
                                <StarHalfOutlinedIcon sx={{ color: "green", fontSize: "18px" }} />
                                <div className={Styles.vertical} />
                                <span >{810}  Reviews</span>
                            </div>
                            <Divider sx={{ borderWidth: "1px", borderColor: "black" }} />


                            <section className={Styles.priceSection}>
                                <div className={Styles.price}>
                                    ₹{productIdDetails?.item_price}
                                </div>
                                <div className={Styles.mrpPrice}>
                                    MRP <span >₹3199</span>
                                </div>
                                <div className={Styles.discount}>
                                    ({22}% OFF)
                                </div>
                            </section>
                            <div className={Styles.includingTax}>
                                inclusive of all taxes
                            </div>

                            <Stack mt={2} >
                                <div className={Styles.title2}>More Colors</div>
                                <section className={Styles.moreColorsBox}>
                                    {
                                        colors.map((color) => (
                                            <div key={color}
                                                style={{ backgroundColor: color }}
                                                onClick={() => selectColorBtn(color)}
                                                className={selectedColor === color && Styles.sizeActive}
                                            />))
                                    }

                                </section>
                            </Stack>

                            <Stack mt={2} >
                                <div className={Styles.title2}>Select Size</div>
                                <section className={Styles.moreSizeBox}>
                                    {
                                        sizes.map((item) => (
                                            <div key={item}
                                                onClick={() => selectSizeBtn(item)}
                                                className={selectedSize === item && Styles.sizeActive}>{item}
                                            </div>
                                        ))
                                    }
                                </section>
                            </Stack>

                            <div className={Styles.btnContainer}>
                                <div>
                                    {
                                        (checkingCart && checkingCart !== undefined) ?
                                            <CounterButton item={checkingCart} />
                                            :
                                            <MButton fullWidth onClick={() => addItemToCart()} >  Add to Cart </MButton>

                                    }
                                </div>

                                <div>
                                    <MButton fullWidth variant="outline">
                                        <FavoriteBorderOutlinedIcon />
                                        <span>
                                            WishList
                                        </span>
                                    </MButton>
                                </div>
                            </div>
                            <Divider sx={{ marginTop: 2, borderWidth: 1, borderColor: "black" }} />
                            <Stack mt={2}>
                                <div className={Styles.descriptionText}><b>Description :</b>{productIdDetails?.item_description}</div>
                            </Stack>


                            {/* <Divider sx={{ marginTop: 2, borderWidth: 1, borderColor: "black" }} />
                            <Stack mt={2}>
                                <div className={Styles.descriptionText}><b>Description :</b>{productIdDetails?.item_description}</div>
                            </Stack>
                            <Divider sx={{ marginTop: 2, borderWidth: 1, borderColor: "black" }} />
                            <Stack mt={2}>
                                <div className={Styles.descriptionText}><b>Description :</b>{productIdDetails?.item_description}</div>
                            </Stack>
                            <Divider sx={{ marginTop: 2, borderWidth: 1, borderColor: "black" }} />
                            <Stack mt={2}>
                                <div className={Styles.descriptionText}><b>Description :</b>{productIdDetails?.item_description}</div>
                            </Stack> */}

                        </motion.section>
                    </Grid>

                </Grid>

            </motion.div>
            <FooterCompoment checkout={false} />
            <CustomeModal show={showDeleteCart} setShow={setShowDeleteCart}>
                <CancelProceedComponent
                    title="Confirm"
                    desc1="They are items in cart from other shop."
                    desc2=" Do you want to remove them?"
                    proccedText="Ok"
                    cancelBtn={() => setShowDeleteCart(false)}
                    proccedBtn={deletePreviousCartItems}
                />

            </CustomeModal>
        </Box>
    )
}

export default ProductDetailsPage

